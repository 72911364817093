import { StoreSlice } from '../types'

type FilterState = Record<string, any>

export interface SolarAssetsFilterSlice {
  solarAssetsFilterSetState: (workspaceId: string) => (newState: FilterState) => void
  solarAssetsFilterState: Record<string, FilterState>
  solarAssetsFilterIsOpen: boolean
  solarAssetsFilterSetIsOpen: (isOpen: boolean) => void
}

export const createSolarAssetsFilterSlice: StoreSlice<SolarAssetsFilterSlice> = (set) => ({
  solarAssetsFilterSetState: (workspaceId) => (newState) =>
    set((state) => ({
      solarAssetsFilterState: {
        ...state.solarAssetsFilterState,
        [workspaceId]: newState,
      },
    })),
  solarAssetsFilterState: {},
  solarAssetsFilterIsOpen: false,
  solarAssetsFilterSetIsOpen: (isOpen) => set({ solarAssetsFilterIsOpen: isOpen }),
})
