import { StoreSlice } from '../types'

export interface AuthorizationSlice {
  isAuthorized: boolean
  setIsAuthorized: (v: boolean) => void
}

export const createAuthorizationSlice: StoreSlice<AuthorizationSlice> = (set, _get) => ({
  isAuthorized: true,
  setIsAuthorized: (v) => set({ isAuthorized: v }),
})
