import { StyleObject } from 'typ'
import { scrollbars } from './scrollbars'

export const websiteLayout: Record<string, StyleObject> = {
  container: {
    maxWidth: ['calc(100% - 48px)', null, null, '1000px'],
    mx: [4, 'auto'],
    py: [7, 8],
    ...scrollbars,
  },
  unstyled: {
    ...scrollbars,
  },
  grid: {
    display: 'grid',
    ...scrollbars,
  },
  'with-side-menu': {
    maxWidth: ['calc(100% - 48px)', null, 'calc(100% - 128px)', null, '1160px'],
    mx: [4, null, 7, null, 'auto'],
    py: [5, null, 8],
    ...scrollbars,
  },
}
