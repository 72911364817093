import { toggleSelection } from 'array'
import { StoreSlice } from '../types'

export interface WebsiteFipCalculatorSlice {
  fipCalculatorAreas: string[]
  fipCalculatorToggleArea: (area: string) => void
  fipCalculatorSetAreas: (areas: string[]) => void
  fipCalculatorPrice: string
  fipCalculatorSetPrice: (price: string) => void
  fipCalculatorDisplayedMode: string
  fipCalculatorSetDisplayedMode: (mode: string) => void
  fipCalculatorSelectedMode: string
  fipCalculatorSetSelectedMode: (mode: string) => void
  fipCalculatorTimeSelection: string
  fipCalculatorSetTimeSelection: (interval: string) => void
}

export const createWebsiteFipCalculatorSlice: StoreSlice<WebsiteFipCalculatorSlice> = (
  set,
  _get
) => ({
  fipCalculatorAreas: ['hokkaido', 'tokyo', 'kyushu'],
  fipCalculatorToggleArea: (area: string) =>
    set((state) => ({ fipCalculatorAreas: toggleSelection(state.fipCalculatorAreas, area) })),
  fipCalculatorSetAreas: (areas: string[]) =>
    set(() => ({
      fipCalculatorAreas: areas,
    })),
  fipCalculatorPrice: '36.0',
  fipCalculatorSetPrice: (price: string) =>
    set(() => ({
      fipCalculatorPrice: price,
    })),
  fipCalculatorDisplayedMode: 'option-1',
  fipCalculatorSetDisplayedMode: (mode: string) =>
    set(() => ({
      fipCalculatorDisplayedMode: mode,
    })),
  fipCalculatorSelectedMode: 'option-1',
  fipCalculatorSetSelectedMode: (mode: string) =>
    set(() => ({
      fipCalculatorSelectedMode: mode,
    })),
  fipCalculatorTimeSelection: 'historical',
  fipCalculatorSetTimeSelection: (interval: string) =>
    set(() => ({
      fipCalculatorTimeSelection: interval,
    })),
})
