import { createSelectorHooks } from 'auto-zustand-selectors-hook'
import { create } from 'zustand'
import { persist } from 'zustand/middleware'
import { tensorWebsiteMigrations } from './migrations'
import {
  createPersistedStoreSlice,
  createSessionStoreSlice,
  TensorWebsitePersistedSlice,
  TensorWebsiteSessionSlice,
} from './root-slices'

const storeBase = create(
  persist(createPersistedStoreSlice, {
    name: 'tensor-website-storage',
    version: 0,
    // SEE https://github.com/pmndrs/zustand/issues/984 FOR AN EXAMPLE ON THE CONCEPT
    // BEHIND MIGRATIONS THROUGH MULTIPLE VERSIONS
    migrate: (persistedState, version) => {
      let state = persistedState

      for (let i = version; i < tensorWebsiteMigrations.length; i++) {
        state = tensorWebsiteMigrations[i](state)
      }

      return state as TensorWebsitePersistedSlice
    },
  })
)
const tensorWebsiteSessionStoreBase = create(createSessionStoreSlice)

// STORE HOOKS
export const useTensorWebsiteStore = createSelectorHooks<TensorWebsitePersistedSlice>(storeBase)
export const useTensorWebsiteSessionStore = createSelectorHooks<TensorWebsiteSessionSlice>(
  tensorWebsiteSessionStoreBase
)
