import { toggleSelection } from 'array'
import { addDays } from 'date-fns'
import {
  IntervalSelectorTimeframe,
  getCurrentJpDay,
  getCurrentJpDayEnd,
  getCurrentJpDayStart,
} from 'datetime'
import { MarketIntelligencePricingZone } from 'generated/tensor-api/generated-types'
import { objectFromKeys } from 'object'
import { ExtendedMarketIntelZone, TranslationFunction } from 'typ'
import {
  ENERGY_CONSUMPTION_API_CODES,
  ENERGY_SOURCE_API_CODES,
  INTRADAY_DATA_OPTIONS,
} from '../constants'
import {
  Certificate,
  EnergyConsumptionApiCode,
  EnergySourceApiCode,
  IntradayChartSelection,
  IntradayDataOption,
  RegionApiCode,
  StoreSlice,
  SupplyDemandUnit,
} from '../types'

const CURRENT_JP_DAY = getCurrentJpDay()
const DAY_START = getCurrentJpDayStart()
const DAY_END = getCurrentJpDayEnd()

export const getApiRegionCodeLabelMap = (
  t: TranslationFunction
): Record<RegionApiCode, string> => ({
  chb: t('common:zones.CHUBU'),
  chg: t('common:zones.CHUGOKU'),
  hkd: t('common:zones.HOKKAIDO'),
  hok: t('common:zones.HOKURIKU'),
  kan: t('common:zones.KANSAI'),
  kyu: t('common:zones.KYUSHU'),
  oki: t('common:zones.OKINAWA'),
  shi: t('common:zones.SHIKOKU'),
  sys: t('common:zones.SYSTEM'),
  toh: t('common:zones.TOHOKU'),
  tok: t('common:zones.TOKYO'),
})

export const getEnergySourceCodeLabelMap = (t: TranslationFunction) =>
  objectFromKeys(ENERGY_SOURCE_API_CODES, (code) =>
    t(`analytics:supply-demand.energy-sources.${code}`)
  )

export const getEnergyConsumptionCodeLabelMap = (t: TranslationFunction) =>
  objectFromKeys(ENERGY_CONSUMPTION_API_CODES, (code) =>
    t(`analytics:supply-demand.energy-consumption.${code}`)
  )

export interface AnalyticsSlice {
  // COMMON
  isRefetching: boolean
  setIsRefetching: (val: boolean) => void

  // GENERATION
  generationGridZone: ExtendedMarketIntelZone
  setGenerationGridZone: (val: ExtendedMarketIntelZone) => void
  generationTypes: EnergySourceApiCode[]
  toggleGenerationTypes: (val: EnergySourceApiCode) => void
  generationStart: Date | null
  setGenerationStart: (val: Date) => void
  generationEnd: Date | null
  setGenerationEnd: (val: Date) => void
  generationTimeframe: IntervalSelectorTimeframe
  setGenerationTimeframe: (val: IntervalSelectorTimeframe) => void
  generationCurrentUnit: SupplyDemandUnit
  setGenerationCurrentUnit: (val: SupplyDemandUnit) => void
  // CONSUMPTION
  consumptionGridZone: ExtendedMarketIntelZone
  setConsumptionGridZone: (val: ExtendedMarketIntelZone) => void
  consumptionTypes: EnergyConsumptionApiCode[]
  toggleConsumptionTypes: (val: EnergyConsumptionApiCode) => void
  consumptionStart: Date | null
  setConsumptionStart: (val: Date) => void
  consumptionEnd: Date | null
  setConsumptionEnd: (val: Date) => void
  consumptionTimeframe: IntervalSelectorTimeframe
  setConsumptionTimeframe: (val: IntervalSelectorTimeframe) => void
  consumptionCurrentUnit: SupplyDemandUnit
  setConsumptionCurrentUnit: (val: SupplyDemandUnit) => void
  // DAY AHEAD
  dayAheadGridZones: MarketIntelligencePricingZone[]
  toggleDayAheadGridZones: (val: MarketIntelligencePricingZone) => void
  dayAheadStart: Date
  setDayAheadStart: (val: Date) => void
  dayAheadEnd: Date
  setDayAheadEnd: (val: Date) => void
  showDayAheadVolume: boolean
  toggleShowDayAheadVolume: () => void
  dayAheadTimeframe: IntervalSelectorTimeframe
  setDayAheadTimeframe: (val: IntervalSelectorTimeframe) => void

  // INTRADAY
  intradayStart: Date
  setIntradayStart: (val: Date) => void
  intradayEnd: Date
  setIntradayEnd: (val: Date) => void
  intradayTimeframe: IntervalSelectorTimeframe
  setIntradayTimeframe: (val: IntervalSelectorTimeframe) => void
  intradayDisplayedData: IntradayDataOption[]
  setIntradayDisplayedData: (val: IntradayDataOption) => void
  intradayShowAverage: boolean
  toggleIntradayShowAverage: VoidFunction
  intradayDisplayedChart: IntradayChartSelection
  setIntradayDisplayedChart: (chart: IntradayChartSelection) => void

  // IMBALANCE
  imbalanceGridZone: MarketIntelligencePricingZone
  setImbalanceGridZone: (val: MarketIntelligencePricingZone) => void
  imbalanceStart: Date
  setImbalanceStart: (val: Date) => void
  imbalanceEnd: Date
  setImbalanceEnd: (val: Date) => void
  imbalanceShowVolume: boolean
  toggleImbalanceShowVolume: VoidFunction
  imbalanceTimeframe: IntervalSelectorTimeframe
  setImbalanceTimeframe: (val: IntervalSelectorTimeframe) => void

  // CAPACITY
  capacityShowMultiPrice: boolean
  toggleCapacityShowMultiPrice: VoidFunction

  // CERTIFICATES
  certificatesStart: Date
  setCertificatesStart: (val: Date) => void
  certificatesEnd: Date
  setCertificatesEnd: (val: Date) => void
  certificatesShowVolume: boolean
  toggleCertificatesShowVolume: VoidFunction
  certificatesType: Certificate
  setCertificatesType: (val: Certificate) => void
}
export const createAnalyticsSlice: StoreSlice<AnalyticsSlice> = (set, _get) => ({
  // COMMON
  isRefetching: false,
  setIsRefetching: (val: boolean) => set(() => ({ isRefetching: val })),

  // GENERATION
  generationGridZone: 'ALL',
  setGenerationGridZone: (val: ExtendedMarketIntelZone) => set(() => ({ generationGridZone: val })),
  generationTypes: ['phy', 'geo', 'bio', 'win', 'sol', 'hyd', 'the', 'nuc', 'itc'],
  toggleGenerationTypes: (val: EnergySourceApiCode) =>
    set((state) => ({
      generationTypes: toggleSelection<EnergySourceApiCode>(state.generationTypes, val),
    })),
  generationStart: null,
  setGenerationStart: (val: Date) =>
    set(() => ({
      generationStart: val,
    })),
  generationEnd: null,
  setGenerationEnd: (val: Date) =>
    set(() => ({
      generationEnd: val,
    })),
  generationTimeframe: 'daily',
  setGenerationTimeframe: (val: IntervalSelectorTimeframe) =>
    set(() => ({
      generationTimeframe: val,
    })),
  generationCurrentUnit: 'energy',
  setGenerationCurrentUnit: (val: SupplyDemandUnit) =>
    set(() => ({
      generationCurrentUnit: val,
    })),
  // CONSUMPTION
  consumptionGridZone: 'ALL',
  setConsumptionGridZone: (val: ExtendedMarketIntelZone) =>
    set(() => ({ consumptionGridZone: val })),
  consumptionTypes: ['win', 'sol', 'phy', 'itc', 'dem'],
  toggleConsumptionTypes: (val: EnergyConsumptionApiCode) =>
    set((state) => ({
      consumptionTypes: toggleSelection<EnergyConsumptionApiCode>(state.consumptionTypes, val),
    })),
  consumptionStart: null,
  setConsumptionStart: (val: Date) =>
    set(() => ({
      consumptionStart: val,
    })),
  consumptionEnd: null,
  setConsumptionEnd: (val: Date) =>
    set(() => ({
      consumptionEnd: val,
    })),
  consumptionTimeframe: 'daily',
  setConsumptionTimeframe: (val: IntervalSelectorTimeframe) =>
    set(() => ({
      consumptionTimeframe: val,
    })),
  consumptionCurrentUnit: 'energy',
  setConsumptionCurrentUnit: (val: SupplyDemandUnit) =>
    set(() => ({
      consumptionCurrentUnit: val,
    })),
  // DAY AHEAD
  dayAheadGridZones: [MarketIntelligencePricingZone.System],
  toggleDayAheadGridZones: (val: MarketIntelligencePricingZone) =>
    set((state) => ({
      dayAheadGridZones: toggleSelection<MarketIntelligencePricingZone>(
        state.dayAheadGridZones,
        val
      ),
    })),
  dayAheadStart: DAY_START,
  setDayAheadStart: (val: Date) =>
    set(() => ({
      dayAheadStart: val,
    })),
  dayAheadEnd: new Date(addDays(new Date(CURRENT_JP_DAY), 2).setHours(23, 59, 59)),
  setDayAheadEnd: (val: Date) =>
    set(() => ({
      dayAheadEnd: val,
    })),
  showDayAheadVolume: true,
  toggleShowDayAheadVolume: () =>
    set((state) => ({ showDayAheadVolume: !state.showDayAheadVolume })),
  dayAheadTimeframe: 'custom',
  setDayAheadTimeframe: (val: IntervalSelectorTimeframe) =>
    set(() => ({
      dayAheadTimeframe: val,
    })),
  //INTRADAY
  intradayTimeframe: 'daily',
  setIntradayTimeframe: (val: IntervalSelectorTimeframe) =>
    set(() => ({
      intradayTimeframe: val,
    })),
  intradayStart: DAY_START,
  setIntradayStart: (val: Date) =>
    set(() => ({
      intradayStart: val,
    })),
  intradayEnd: DAY_END,
  setIntradayEnd: (val: Date) =>
    set(() => ({
      intradayEnd: val,
    })),
  intradayDisplayedData: [...INTRADAY_DATA_OPTIONS],
  setIntradayDisplayedData: (val: IntradayDataOption) =>
    set((state) => ({
      intradayDisplayedData: toggleSelection<IntradayDataOption>(state.intradayDisplayedData, val),
    })),
  intradayShowAverage: true,
  toggleIntradayShowAverage: () =>
    set((state) => ({
      intradayShowAverage: !state.intradayShowAverage,
    })),
  intradayDisplayedChart: 'confidence-bar',
  setIntradayDisplayedChart: (chart: IntradayChartSelection) =>
    set(() => ({
      intradayDisplayedChart: chart,
    })),

  // IMBALANCE
  imbalanceGridZone: MarketIntelligencePricingZone.Hokkaido,
  setImbalanceGridZone: (val: MarketIntelligencePricingZone) =>
    set(() => ({
      imbalanceGridZone: val,
    })),
  imbalanceStart: DAY_START,
  setImbalanceStart: (val: Date) =>
    set(() => ({
      imbalanceStart: val,
    })),
  imbalanceEnd: DAY_END,
  setImbalanceEnd: (val: Date) =>
    set(() => ({
      imbalanceEnd: val,
    })),
  imbalanceShowVolume: true,
  toggleImbalanceShowVolume: () =>
    set((state) => ({
      imbalanceShowVolume: !state.imbalanceShowVolume,
    })),
  imbalanceTimeframe: 'daily',
  setImbalanceTimeframe: (val: IntervalSelectorTimeframe) =>
    set(() => ({
      imbalanceTimeframe: val,
    })),

  // CAPACITY
  capacityShowMultiPrice: true,
  toggleCapacityShowMultiPrice: () =>
    set((state) => ({
      capacityShowMultiPrice: !state.capacityShowMultiPrice,
    })),
  // CERTIFICATES

  certificatesStart: new Date(new Date().setUTCFullYear(2020, 0, 1)),
  setCertificatesStart: (val: Date) =>
    set(() => ({
      certificatesStart: val,
    })),
  certificatesEnd: DAY_END,
  setCertificatesEnd: (val: Date) =>
    set(() => ({
      certificatesEnd: val,
    })),
  certificatesShowVolume: true,
  toggleCertificatesShowVolume: () =>
    set((state) => ({
      certificatesShowVolume: !state.certificatesShowVolume,
    })),
  certificatesType: 'nfn',
  setCertificatesType: (val: Certificate) =>
    set(() => ({
      certificatesType: val,
    })),
})
