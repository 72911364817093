export const getConfig = () => {
  const region = process.env.NEXT_PUBLIC_AWS_REGION
  const cognitoAttributes = ['USERNAME']
  return {
    aws_project_region: region,
    aws_cognito_identity_pool_id:
      process.env.NEXT_PUBLIC_COGNITO_IDENTITY_POOL_ID,
    aws_cognito_region: region,
    aws_user_pools_id: process.env.NEXT_PUBLIC_AWS_USER_POOLS_ID,
    aws_user_pools_web_client_id:
      process.env.NEXT_PUBLIC_AWS_USER_POOLS_WEB_CLIENT_ID,
    oauth: {},
    aws_cognito_username_attributes: cognitoAttributes,
    aws_cognito_social_providers: [],
    aws_cognito_signup_attributes: cognitoAttributes,
    aws_cognito_mfa_configuration: 'OFF',
    aws_cognito_mfa_types: ['SMS'],
    aws_cognito_password_protection_settings: {
      passwordPolicyMinLength: 8,
      passwordPolicyCharacters: [],
    },
    aws_cognito_verification_mechanisms: cognitoAttributes,
    authenticationFlowType: 'USER_PASSWORD_AUTH',
  }
}

export default getConfig
