/**
 *
 * @param curr Current index in the array
 * @param arr The array we are cycling through
 * @returns The following index in the array cycle
 */
export const cycleThrough = (
  curr: number,
  arr: any[],
  direction: 'next' | 'prev' = 'next',
  returnItems = false
) => {
  if (!arr.length) return -1
  let idx = 0
  if (direction === 'next') {
    idx = curr >= arr.length - 1 ? 0 : curr + 1
  } else {
    idx = curr <= 0 ? arr.length - 1 : curr - 1
  }
  return returnItems ? arr[idx] : idx
}
