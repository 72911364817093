import { unique } from './unique'
import { SortOrder } from './types'

type Options = { order?: SortOrder; locale?: string }

const japanesePrefectures = {
  en: [
    'system',
    'aichi',
    'akita',
    'aomori',
    'chiba',
    'ehime',
    'fukui',
    'fukuoka',
    'fukushima',
    'gifu',
    'gunma',
    'hiroshima',
    'hokkaido',
    'hyogo',
    'ibaraki',
    'ishikawa',
    'iwate',
    'kyoto',
    'kagawa',
    'kagoshima',
    'kanagawa',
    'kochi',
    'kumamoto',
    'mie',
    'miyagi',
    'miyazaki',
    'nagano',
    'nagasaki',
    'nara',
    'niigata',
    'oita',
    'okayama',
    'okinawa',
    'osaka',
    'saga',
    'saitama',
    'shiga',
    'shimane',
    'shizuoka',
    'tochigi',
    'tokyo',
    'tokushima',
    'tottori',
    'toyama',
    'wakayama',
    'yamanashi',
    'yamagata',
    'yamaguchi',
  ],
  ja: [
    'システム',
    '北海道',
    '青森',
    '岩手',
    '宮城',
    '秋田',
    '山形',
    '福島',
    '茨城',
    '栃木',
    '群馬',
    '埼玉',
    '千葉',
    '東京',
    '神奈川',
    '新潟',
    '富山',
    '石川',
    '福井',
    '山梨',
    '長野',
    '岐阜',
    '静岡',
    '愛知',
    '三重',
    '滋賀',
    '京都',
    '大阪',
    '兵庫',
    '奈良',
    '和歌山',
    '鳥取',
    '島根',
    '岡山',
    '広島',
    '山口',
    '徳島',
    '香川',
    '愛媛',
    '高知',
    '福岡',
    '佐賀',
    '長崎',
    '熊本',
    '大分',
    '宮崎',
    '鹿児島',
    '沖縄',
    'system',
    'hokkaido',
    'aomori',
    'iwate',
    'miyagi',
    'akita',
    'yamagata',
    'fukushima',
    'ibaraki',
    'tochigi',
    'gunma',
    'saitama',
    'chiba',
    'tokyo',
    'kanagawa',
    'niigata',
    'toyama',
    'ishikawa',
    'fukui',
    'yamanashi',
    'nagano',
    'gifu',
    'shizuoka',
    'aichi',
    'mie',
    'shiga',
    'kyoto',
    'osaka',
    'hyogo',
    'nara',
    'wakayama',
    'tottori',
    'shimane',
    'okayama',
    'hiroshima',
    'yamaguchi',
    'tokushima',
    'kagawa',
    'ehime',
    'kochi',
    'fukuoka',
    'saga',
    'nagasaki',
    'kumamoto',
    'oita',
    'miyazaki',
    'kagoshima',
    'okinawa',
  ],
}

export function sortByPrefecture<
  T extends string | number | Record<string, any>
>(
  arr: T[],
  options?: Options | null,
  accessor: (el: T) => string = (el) => el as unknown as string
): T[] {
  const uniquePrefectures = unique([...arr])
  const order = options?.order ?? 'asc'
  const locale = options?.locale === 'ja' ? 'ja' : 'en'

  if (locale === 'ja')
    return uniquePrefectures.sort((a, b) => {
      const indexA = japanesePrefectures[locale].findIndex((name) =>
        accessor(a).toLocaleLowerCase().includes(name)
      )
      const indexB = japanesePrefectures[locale].findIndex((name) =>
        accessor(b).toLocaleLowerCase().includes(name)
      )
      return order === 'asc' ? indexA - indexB : indexB - indexA
    })

  return uniquePrefectures.sort((a, b) => {
    const indexA = japanesePrefectures[locale].indexOf(
      accessor(a).toLocaleLowerCase()
    )
    const indexB = japanesePrefectures[locale].indexOf(
      accessor(b).toLocaleLowerCase()
    )
    return order === 'asc' ? indexA - indexB : indexB - indexA
  })
}
