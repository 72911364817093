import { areSameType, isPrimitive } from 'typeguards'

export function ascending<T, U>(a: T, b: U) {
  if (!isPrimitive(a) || !isPrimitive(b) || !areSameType(a, b)) return NaN
  return a == null || b == null
    ? NaN
    : a < b
    ? -1
    : a > b
    ? 1
    : a >= b
    ? 0
    : NaN
}
