/* eslint-disable */

import { Maybe } from 'typ'
import { isUndefined, isNotNil } from 'typeguards'

export function min(values: Maybe<number>[]): number {
  let min: Maybe<number>
  values.forEach((value) => {
    if (
      isNotNil(value) &&
      ((isNotNil(min) && min > value) || (isUndefined(min) && value >= value))
    ) {
      min = value
    }
  })

  return min ?? 0
}
