import { isUndefined, isNotNil } from 'typeguards'
import { Maybe } from 'typ'

/* eslint-disable */
export function max(values: Maybe<number>[]): number {
  let max: Maybe<number>
  values.forEach((value) => {
    if (
      isNotNil(value) &&
      ((isNotNil(max) && max < value) || (isUndefined(max) && value >= value))
    ) {
      max = value
    }
  })
  return max ?? 0
}
