import { StoreSlice } from '../types'

type FilterState = Record<string, any>

export interface ReportingActualsFilterSlice {
  reportingActualsBaselineId: string | null
  reportingActualsSetBaselineId: (baselineId: string | null) => void
  reportingActualsFilterSetState: (workspaceId: string) => (newState: FilterState) => void
  reportingActualsFilterState: Record<string, FilterState>
  reportingActualsFilterIsOpen: boolean
  reportingActualsFilterSetIsOpen: (isOpen: boolean) => void
}

export const createReportingActualsFilterSlice: StoreSlice<ReportingActualsFilterSlice> = (
  set
) => ({
  reportingActualsBaselineId: null,
  reportingActualsSetBaselineId: (baselineId) => set({ reportingActualsBaselineId: baselineId }),
  reportingActualsFilterSetState: (workspaceId) => (newState) =>
    set((state) => ({
      reportingActualsFilterState: {
        ...state.reportingActualsFilterState,
        [workspaceId]: newState,
      },
    })),
  reportingActualsFilterState: {},
  reportingActualsFilterIsOpen: false,
  reportingActualsFilterSetIsOpen: (isOpen) => set({ reportingActualsFilterIsOpen: isOpen }),
})
