import { useWindowVisibilityObserver } from 'hooks'
import { useCallback } from 'react'

interface UseApolloPollingWithVisibilityOptions {
  startPolling: (interval: number) => void
  stopPolling: () => void
  interval: number
  refetch: () => Promise<any>
}

export const useApolloPollingWithVisibility = ({
  startPolling,
  stopPolling,
  interval,
  refetch,
}: UseApolloPollingWithVisibilityOptions) => {
  const isVisibleRef = useWindowVisibilityObserver((visible) => {
    if (visible) {
      startPolling(interval)
      refetch?.()
    } else {
      stopPolling()
    }
  })

  const visibilityAwareStartPolling = useCallback(
    (pollingInterval: number) => {
      if (isVisibleRef.current) startPolling(pollingInterval)
    },
    [startPolling, isVisibleRef]
  )

  return { startPolling: visibilityAwareStartPolling, stopPolling }
}
