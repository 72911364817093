/**
 *
 * @param arr The array containing the values to toggle
 * @param item The current selection
 * @returns A filtered array according to selection
 */
export const toggleSelection = <T extends string | number>(
  arr: T[],
  item: T
): T[] =>
  arr.includes(item) ? arr.filter((el) => el !== item) : [...arr, item]
