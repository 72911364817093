import { GENERIC_SERVER_ERROR_KEY } from 'errors'
import useTranslation from 'next-translate/useTranslation'
import store from 'store'
import { toast } from 'ui-v2'

export const d = {
  contactSupport: 'common:contact-support-short',
}
type UseHandleGenericServerErrorArgs = {
  cb?: VoidFunction
}
export const useHandleGenericServerError = ({ cb }: UseHandleGenericServerErrorArgs = {}) => {
  const openContactSupportModal = store.useOpenContactSupportModal()
  const { t } = useTranslation()

  return (toastId: string) => {
    cb?.()
    toast.error(
      {
        description: t(GENERIC_SERVER_ERROR_KEY),
        buttonProps: { onPress: openContactSupportModal, label: t(d.contactSupport) },
      },
      { toastId }
    )
  }
}
