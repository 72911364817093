import { GetState, SetState } from 'zustand'
import {
  createAdminNavigationSlice,
  createAdminToastSlice,
  createAdminUserPreferencesSlice,
} from '../slices'

export const createPersistedStoreSlice = (set: SetState<any>, get: GetState<any>) => ({
  ...createAdminToastSlice(set, get),
  ...createAdminUserPreferencesSlice(set, get),
})
export type TensorAdminPersistedSlice = ReturnType<typeof createPersistedStoreSlice>

export const createSessionStoreSlice = (set: SetState<any>, get: GetState<any>) => ({
  ...createAdminNavigationSlice(set, get),
})

export type TensorAdminSessionSlice = ReturnType<typeof createSessionStoreSlice>
