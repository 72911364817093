import {
  ResourceIsAssignedProblem,
  WorkspaceAlreadyExistsProblem,
  WorkspaceAlreadyExistsProblemType,
} from 'generated/tensor-api/generated-types'
import { log } from 'logger'
import Trans from 'next-translate/Trans'
import { toast } from 'toast'
import { isNotEmpty } from 'typeguards'
import {
  AUTHORIZATION_ERROR_KEY,
  RESOURCE_ALREADY_EXISTS_KEY,
  RESOURCE_DOES_NOT_EXIST_KEY,
  RESOURCE_NOT_AVAILABLE_KEY,
  WORKSPACE_EXISTS_KEY,
  WORKSPACE_ID_EXISTS_KEY,
} from './message-keys'
import {
  hasAuthorizationProblem,
  hasResourceAlreadyExistsProblem,
  hasResourceDoesNotExistProblem,
  hasResourceNotAvailableProblem,
} from './problem-checkers'
import { BaseError } from './types'

type ProblemHandlerOptions = {
  cb?: VoidFunction
  toastContainerId?: string
  toastMsgI18nKey?: string
}

/**
 *
 * @param errors A list of ProblemInterfaces
 * @description handles mutation authorization problems with a standard toast message
 */

export function handleAuthorizationProblem(errors: BaseError[], options?: ProblemHandlerOptions) {
  if (hasAuthorizationProblem(errors)) {
    toast.error(<Trans i18nKey={options?.toastMsgI18nKey ?? AUTHORIZATION_ERROR_KEY} />, {
      containerId: options?.toastContainerId ?? 'default',
    })
    log.debug('AuthorizationProblem')
    options?.cb?.()
  }
}

/**
 *
 * @param errors A list of ProblemInterfaces
 * @description handles mutation unique check problems with a standard toast message
 */

export const handleResourceAlreadyExistsProblem = (
  errors: BaseError[],
  options?: ProblemHandlerOptions
) => {
  if (hasResourceAlreadyExistsProblem(errors)) {
    toast.error(<Trans i18nKey={options?.toastMsgI18nKey ?? RESOURCE_ALREADY_EXISTS_KEY} />, {
      containerId: options?.toastContainerId ?? 'default',
    })
    options?.cb?.()
    log.debug('ResourceAlreadyExistsProblem')
  }
}

/**
 *
 * @param errors A list of ProblemInterfaces
 * @description handles mutation deleted resource problems with a standard toast message
 */
export const handleResourceDoesNotExistProblem = (
  errors: BaseError[],
  options?: ProblemHandlerOptions
) => {
  if (hasResourceDoesNotExistProblem(errors)) {
    toast.error(<Trans i18nKey={options?.toastMsgI18nKey ?? RESOURCE_DOES_NOT_EXIST_KEY} />, {
      containerId: options?.toastContainerId ?? 'default',
    })
    options?.cb?.()
    log.debug('ResourceDoesNotExistProblem')
  }
}

/**
 *
 * @param errors A list of ProblemInterfaces
 * @description handles mutation deleted resource problems with a standard toast message
 */
export const handleResourceIsAssignedErrors = (
  errors: BaseError[],
  options: Partial<Omit<ProblemHandlerOptions, 'cb'>> & {
    cb?: (errors: ResourceIsAssignedProblem[]) => void
    toastMsgI18nKey: string
  }
) => {
  const resourceIsAssignedErrors = errors.filter(
    (error): error is ResourceIsAssignedProblem => error.__typename === 'ResourceIsAssignedProblem'
  )

  if (isNotEmpty(resourceIsAssignedErrors)) {
    toast.error(<Trans i18nKey={options.toastMsgI18nKey} values={{ count: errors.length }} />)
    log.debug('ResourceIsAssignedProblem')
    options?.cb?.(resourceIsAssignedErrors)
  }
}

/**
 *
 * @param errors A list of ProblemInterfaces
 * @description handles mutation unique check problems with a standard toast message
 */

export const handleWorkspaceAlreadyExistsProblem = (
  errors: BaseError[],
  options?: Omit<ProblemHandlerOptions, 'toastMsgI18nKey'>
) => {
  const toastOptions = {
    containerId: options?.toastContainerId ?? 'default',
  }
  const isWorkspaceAlreadyExistsProblem = (
    errs: BaseError[] | WorkspaceAlreadyExistsProblem[]
  ): errs is WorkspaceAlreadyExistsProblem[] =>
    errs.some((err) => err.__typename === 'WorkspaceAlreadyExistsProblem')

  if (isWorkspaceAlreadyExistsProblem(errors)) {
    if (errors.some((err) => err.type === WorkspaceAlreadyExistsProblemType.Id)) {
      toast.error(<Trans i18nKey={WORKSPACE_ID_EXISTS_KEY} />, toastOptions)
      log.debug('WorkspaceAlreadyExistsProblem')
    } else {
      toast.error(<Trans i18nKey={WORKSPACE_EXISTS_KEY} />, toastOptions)
      log.debug('WorkspaceAlreadyExistsProblem')
    }
    options?.cb?.()
  }
}

/**
 *
 * @param errors A list of ProblemInterfaces
 * @param cb An error handler callback
 * @description handles logs and handles resource not available problems with a custom cb
 */

export const handleResourceNotAvailableProblem = (
  errors: BaseError[],
  options?: ProblemHandlerOptions
) => {
  if (hasResourceNotAvailableProblem(errors)) {
    toast.error(<Trans i18nKey={options?.toastMsgI18nKey ?? RESOURCE_NOT_AVAILABLE_KEY} />, {
      containerId: options?.toastContainerId ?? 'default',
    })
    log.debug('ResourceNotAvailableProblem')
    options?.cb?.()
  }
}
