import {
  ApolloLink,
  Observable,
  ApolloClient,
  ApolloProvider,
  InMemoryCache,
} from '@apollo/client'
import { GraphQLError } from 'graphql'
import { FC, PropsWithChildren } from 'react'

export interface ErrorProviderProps extends PropsWithChildren {
  graphQLErrors?: GraphQLError[]
}
export const ErrorProvider: FC<ErrorProviderProps> = (props) => {
  // This is just a link that swallows all operations and returns the same thing
  // for every request: The specified error.
  const link = new ApolloLink(
    (_operation) =>
      new Observable((observer) => {
        observer.next({
          errors: props.graphQLErrors || [
            new GraphQLError('Unspecified error from ErrorProvider'),
          ],
        })
        observer.complete()
      })
  )

  const client = new ApolloClient({
    link,
    cache: new InMemoryCache(),
  })

  return <ApolloProvider client={client}>{props.children}</ApolloProvider>
}
