import { StoreSlice } from '../types'

export interface TradingSlice {
  isTradingHistoryGroupedByDate: boolean
  setIsTradingHistoryGroupedByDate: (isGroupedByDate: boolean) => void
}

export const createTradingSlice: StoreSlice<TradingSlice> = (set, _get) => ({
  isTradingHistoryGroupedByDate: true,
  setIsTradingHistoryGroupedByDate: (isGroupedByDate: boolean) =>
    set(() => ({
      isTradingHistoryGroupedByDate: isGroupedByDate,
    })),
})
