import { StepVariant } from 'typ'
import { DEMAND_DATA_UPLOAD_STEPS as STEPS } from '../constants'
import { DemandDataUploadStepId, OperationUploadsAcceptedFile, StoreSlice } from '../types'

type Step = {
  variant: StepVariant
  isActive: boolean
}
const INITIAL_STEPS: Step[] = [
  {
    variant: 'in-progress',
    isActive: true,
  },
  {
    variant: 'pending',
    isActive: false,
  },
]

export interface DemandDataUploadSlice {
  demandDataUploadSteps: Step[]
  demandDataAcceptedFiles: OperationUploadsAcceptedFile[]
  demandDataSelectedAssetId: string
  setDemandDataAcceptedFiles: (files: OperationUploadsAcceptedFile[]) => void
  addDemandDataAcceptedFiles: (files: OperationUploadsAcceptedFile[]) => void
  removeDemandDataAcceptedFile: (id: string) => void
  setDemandDataSelectedAssetId: (assetId: string) => void
  selectDemandDataUploadStep: (step: DemandDataUploadStepId) => void
  completeDemandAssetSelectionStep: VoidFunction
  resetDemandDataUpload: VoidFunction
  getDemandDataActiveStep: () => DemandDataUploadStepId
}

const initialValues = {
  demandDataUploadSteps: INITIAL_STEPS,
  demandDataAcceptedFiles: [],
  demandDataSelectedAssetId: '',
}

export const createDemandDataUploadSlice: StoreSlice<DemandDataUploadSlice> = (set, get) => ({
  ...initialValues,
  setDemandDataSelectedAssetId: (assetId: string) => set({ demandDataSelectedAssetId: assetId }),
  setDemandDataAcceptedFiles: (files: OperationUploadsAcceptedFile[]) =>
    set({ demandDataAcceptedFiles: files }),
  addDemandDataAcceptedFiles: (files: OperationUploadsAcceptedFile[]) => {
    const existingFiles = get().demandDataAcceptedFiles
    set({ demandDataAcceptedFiles: [...files, ...existingFiles] })
  },
  removeDemandDataAcceptedFile: (id: string) => {
    const files = get().demandDataAcceptedFiles
    set({
      demandDataAcceptedFiles: files.filter((f) => f.data.id !== id),
    })
  },
  completeDemandAssetSelectionStep: () => {
    const steps: Step[] = [
      {
        variant: 'completed',
        isActive: false,
      },
      {
        variant: 'in-progress',
        isActive: true,
      },
    ]
    set({ demandDataUploadSteps: steps })
  },
  selectDemandDataUploadStep: (step) => {
    const state = get()
    if (state.demandDataUploadSteps[0].variant !== 'completed') return
    const stepIndex = STEPS.indexOf(step)
    const steps = state.demandDataUploadSteps.map((s, i) => ({
      ...s,
      isActive: i === stepIndex,
    }))
    set({ demandDataUploadSteps: steps })
  },
  getDemandDataActiveStep: () => {
    const state = get()
    return STEPS[state.demandDataUploadSteps.findIndex((s) => s.isActive)]
  },
  resetDemandDataUpload: () => set(initialValues),
})
