/* eslint-disable  */
import { CSSObject } from 'typ'
import { TextStyleVariant, TextUtilityVariant } from './text'
import { ellipsize } from './utils'

const baseHeadingStyles: CSSObject = {
  color: 'text',
  margin: 0,
}

const baseBodyStyles: CSSObject = {
  fontFamily: 'body',
  color: 'text',
  lineHeight: 'body',
  fontWeight: 'normal',
}

export const websiteText: Record<TextStyleVariant | TextUtilityVariant, CSSObject> = {
  ellipsize,
  h1: {
    ...baseHeadingStyles,
    fontSize: ['39px', '57px', null, null, '67px'],
    fontWeight: 'bold',
    lineHeight: '110%',
  },
  h2: {
    ...baseHeadingStyles,
    fontSize: ['33px', '50px'],
    lineHeight: '120%',
    fontWeight: 'bold',
  },
  h3: {
    ...baseHeadingStyles,
    fontSize: ['27px', '37px'],
    lineHeight: '125%',
    fontWeight: 'bold',
  },
  h4: {
    ...baseHeadingStyles,
    fontWeight: 'bold',
    fontSize: ['23px', '28px'],
    lineHeight: '130%',
  },
  h5: {
    ...baseHeadingStyles,
    fontWeight: 'semi-bold',
    fontSize: ['19px', '21px'],
    lineHeight: '140%',
  },
  h6: {
    ...baseHeadingStyles,
    fontSize: 3,
    lineHeight: 'body',
    fontWeight: 'medium',
  },
  paragraph1: {
    ...baseBodyStyles,
    fontSize: 3,
    marginBottom: 3,
  },
  paragraph2: {},
  body1: {
    ...baseBodyStyles,
    fontSize: '18px',
  },
  body2: {
    ...baseBodyStyles,
    fontSize: 3,
  },
  body3: {
    ...baseBodyStyles,
    fontSize: 2,
  },
  secondary1: {
    ...baseBodyStyles,
    fontSize: '18px',
    color: 'textGray',
  },
  secondary2: {
    ...baseBodyStyles,
    fontSize: 3,
    color: 'textGray',
  },
  secondary3: {
    ...baseBodyStyles,
    fontSize: 2,
    color: 'textGray',
  },
  capsHeader: {
    fontFamily: 'body',
    textTransform: 'uppercase',
    fontSize: 2,
    color: 'text',
    fontWeight: 'medium',
    lineHeight: '21px',
    letterSpacing: '1px',
  },
  title1: {
    ...baseBodyStyles,
    fontSize: ['19px', '21px'],
  },
  title2: {
    ...baseBodyStyles,
    fontSize: ['19px'],
  },
  title3: {
    ...baseBodyStyles,
    fontSize: ['18px'],
  },
  helper1: {
    ...baseBodyStyles,
    fontSize: 3,
    fontWeight: 'medium',
  },
  helper2: {
    ...baseBodyStyles,
    fontSize: 2,
    fontWeight: 'medium',
  },
  disabled: {
    fontFamily: 'body',
    color: 'gray3',
    cursor: 'not-allowed',
    lineHeight: 'body',
  },
}
