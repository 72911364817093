import { StoreSlice } from '../types'

export interface SettingsReturnUrlSlice {
  settingsReturnUrl: string | null
  setSettingsReturnUrl: (user: string | null) => void
}

export const createSettingsReturnUrlSlice: StoreSlice<SettingsReturnUrlSlice> = (set, _get) => ({
  settingsReturnUrl: null,
  setSettingsReturnUrl: (settingsReturnUrl) => set({ settingsReturnUrl }),
})
