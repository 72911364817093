import { makeTheme } from './utils/make-theme'
import { websiteForms } from './assets/website-forms'
import { websiteLayout } from './assets/website-layout'
import { websiteBreakpoints } from './assets/website-breakpoints'
import {
  badges,
  buttons,
  colors,
  fontSizes,
  fontWeights,
  fonts,
  forms,
  lineHeights,
  links,
  noSelect,
  pills,
  radii,
  roundedScrollbars,
  scrollbars,
  shadows,
  sizes,
  space,
  zIndices,
  websiteText,
  websiteStyles,
} from './assets'

export const themeBase = {
  badges,
  buttons,
  colors,
  fontSizes,
  fontWeights,
  fonts,
  forms,
  lineHeights,
  links,
  noSelect,
  pills,
  radii,
  roundedScrollbars,
  scrollbars,
  shadows,
  sizes,
  space,
  zIndices,
}

const websiteColors = {
  ...colors,
  textGray: '#737A9B',
}

export const websiteThemeBase = {
  ...themeBase,
  breakpoints: websiteBreakpoints,
  colors: websiteColors,
  forms: websiteForms,
  layout: websiteLayout,
  rawColors: websiteColors,
  styles: websiteStyles,
  text: websiteText,
}

export const websiteTheme = makeTheme(websiteThemeBase)

export const makeLocalizedWebsiteTheme = (
  // unused yet, but needed for simmetry with other localized theme makers
  _lang: string,
  options: { fontFamily: string }
) =>
  makeTheme({
    ...websiteThemeBase,
    fonts: {
      body: `${options.fontFamily}`,
      heading: `${options.fontFamily}`,
      monospace: `${options.fontFamily}`,
    },
  })
