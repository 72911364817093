type Primitive = number | string | boolean | Date

export function isPrimitive(x: any): x is Primitive {
  return (
    typeof x === 'number' ||
    typeof x === 'string' ||
    typeof x === 'boolean' ||
    x instanceof Date
  )
}
