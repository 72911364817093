/* eslint-disable  */
import { StepVariant } from 'typ'
import { v4 } from 'uuid'
import {
  SPV_ASSETS_FORM_INITIAL_VALUES,
  SPV_FINANCIAL_INFORMATION_FORM_INITIAL_VALUES,
  SPV_LEGAL_ENTITY_FORM_INITIAL_VALUES,
  NEW_SPV_CREATION_STEPS as STEPS,
} from '../constants'
import {
  NewSpvCreationStepId,
  SpvAssetsFormValues,
  SpvCreationSliceFormValues,
  SpvFinancialFormValues,
  SpvFormValues,
  SpvInvestmentFormValues,
  SpvLegalEntityFormValues,
  SpvLoanFormValues,
  StoreSlice,
} from '../types'

type Step = {
  errors: string[]
  variant: StepVariant
  isActive: boolean
  lastSubmitted: number
}

export type SpvCreationDataSlice = SpvCreationSliceFormValues & {
  spvCreationSteps: Step[]
  isSpvCreationNameTaken: boolean
  isSubmittingSpvCreationForm: boolean
  isSpvCreationComplete: boolean
  setIsSpvCreationComplete: (value: boolean) => void
  setIsSubmittingSpvCreationForm: (value: boolean) => void
  setSpvSelectedAssets: (assets: string[]) => void
  setIsSpvCreationNameTaken: (value: boolean) => void
  toggleSpvCreationStepCompletion: (step: NewSpvCreationStepId) => void
  updateSpvCreationStepErrors: (step: NewSpvCreationStepId, errors: string[]) => void
  selectSpvCreationStep: (step: NewSpvCreationStepId) => void
  resetSpvCreationForm: VoidFunction
  setSpvCreationLegalEntityalues: (values: SpvLegalEntityFormValues) => void
  setSpvCreationFinancialValues: (values: SpvFinancialFormValues) => void
  setSpvCreationAssetsValues: (values: SpvAssetsFormValues) => void
  getSpvCreationFormData: () => SpvFormValues
  getSpvCreationActiveStep: () => NewSpvCreationStepId
  updateSpvCreationLastSubmitted: (step: NewSpvCreationStepId, ms?: number) => void
  resetSpvCreationSectionErrors: (step: NewSpvCreationStepId) => void
}

export const INITIAL_SPV_INVESTMENT_VALUE: Omit<
  SpvInvestmentFormValues,
  'id' | 'rowCreatedAt' | 'selected'
> = {
  amount: '',
  investmentDate: '',
  investorName: '',
}

export const INITIAL_SPV_LOAN_VALUE: Omit<SpvLoanFormValues, 'id' | 'rowCreatedAt' | 'selected'> = {
  amount: '',
  description: '',
  interestRate: '',
  lenderName: '',
  loanReceivedDate: '',
  repaymentEndDate: '',
  repaymentFrequency: '',
  repaymentStartDate: '',
}

const INITIAL_STEPS: Step[] = [
  {
    errors: [],
    variant: 'in-progress',
    isActive: true,
    lastSubmitted: 0,
  },
  {
    errors: [],
    variant: 'pending',
    isActive: false,
    lastSubmitted: 0,
  },
  {
    errors: [],
    variant: 'pending',
    isActive: false,
    lastSubmitted: 0,
  },
]

const getInitialValues = () => ({
  isSpvCreationNameTaken: false,
  isSubmittingSpvCreationForm: false,
  isSpvCreationComplete: false,
  spvCreationSteps: INITIAL_STEPS,
  spvCreationLegalEntityValues: SPV_LEGAL_ENTITY_FORM_INITIAL_VALUES,
  spvCreationFinancialValues: {
    ...SPV_FINANCIAL_INFORMATION_FORM_INITIAL_VALUES,
    investments: [
      {
        id: v4(),
        rowCreatedAt: 0,
        selected: false,
        ...INITIAL_SPV_INVESTMENT_VALUE,
      },
    ],
    loans: [
      {
        id: v4(),
        rowCreatedAt: 0,
        selected: false,
        ...INITIAL_SPV_LOAN_VALUE,
      },
    ],
  },
  spvCreationAssetsValues: SPV_ASSETS_FORM_INITIAL_VALUES,
})

export const createSpvCreationDataSlice: StoreSlice<SpvCreationDataSlice> = (set, get) => ({
  ...getInitialValues(),
  setIsSpvCreationComplete: (value: boolean) => set(() => ({ isSpvCreationComplete: value })),
  setSpvSelectedAssets: (assets) => set(() => ({ spvCreationAssetsValues: { assets } })),
  setIsSubmittingSpvCreationForm: (value: boolean) =>
    set(() => ({ isSubmittingSpvCreationForm: value })),
  setIsSpvCreationNameTaken: (value: boolean) => set(() => ({ isSpvCreationNameTaken: value })),
  toggleSpvCreationStepCompletion: (step) => {
    const state = get()
    if (state.isSubmittingSpvCreationForm || state.isSpvCreationComplete) return
    const stepIndex = STEPS.indexOf(step)
    const steps: Step[] = state.spvCreationSteps.map((s, i) => {
      if (i <= stepIndex) {
        return {
          ...s,
          variant: 'completed',
          isActive: false,
          hasSumbitted: true,
        }
      }
      if (i === stepIndex + 1) {
        return {
          ...s,
          variant: s.variant === 'pending' ? 'in-progress' : s.variant,
          isActive: true,
        }
      }
      return s
    })
    set(() => ({ spvCreationSteps: steps }))
  },
  updateSpvCreationStepErrors: (step, errors) => {
    const state = get()
    const stepIndex = STEPS.indexOf(step)
    const lastTouchedStep = state.spvCreationSteps.findIndex((s) => s.variant === 'in-progress')
    const steps: Step[] = state.spvCreationSteps.map((s, i) => {
      if (i === stepIndex) {
        return {
          ...s,
          errors,
          variant: errors.length
            ? 'error'
            : lastTouchedStep > stepIndex
              ? 'completed'
              : 'in-progress',
        }
      }
      return s
    })
    set(() => ({ spvCreationSteps: steps }))
  },
  selectSpvCreationStep: (step) => {
    const state = get()
    if (state.isSubmittingSpvCreationForm || state.isSpvCreationComplete) return
    const stepIndex = STEPS.indexOf(step)
    const currentStepIndex = state.spvCreationSteps.findIndex((s) => s.isActive)
    const steps: Step[] = state.spvCreationSteps.map((s, i) => ({
      ...s,
      isActive: i === stepIndex,
      variant:
        i === currentStepIndex && !s.errors.length && stepIndex > currentStepIndex
          ? 'completed'
          : s.variant,
    }))
    set(() => ({
      spvCreationSteps: steps,
    }))
  },
  updateSpvCreationLastSubmitted: (step, ms = Date.now()) => {
    const state = get()
    const stepIndex = STEPS.indexOf(step)
    const steps: Step[] = state.spvCreationSteps.map((s, i) => ({
      ...s,
      lastSubmitted: i === stepIndex ? ms : s.lastSubmitted,
    }))
    set(() => ({ spvCreationSteps: steps }))
  },

  setSpvCreationLegalEntityalues: (values) => set(() => ({ spvCreationLegalEntityValues: values })),
  setSpvCreationFinancialValues: (values) => set(() => ({ spvCreationFinancialValues: values })),
  setSpvCreationAssetsValues: (values) => set(() => ({ spvCreationAssetsValues: values })),
  getSpvCreationFormData: () => {
    const state = get()
    return {
      ...state.spvCreationLegalEntityValues,
      ...state.spvCreationFinancialValues,
      ...state.spvCreationAssetsValues,
    }
  },
  getSpvCreationActiveStep: () => {
    const state = get()
    return STEPS[state.spvCreationSteps.findIndex((s) => s.isActive)]
  },
  resetSpvCreationSectionErrors: (step) => {
    const state = get()
    state.updateSpvCreationStepErrors(step, [])
    state.updateSpvCreationLastSubmitted(step, 0)
  },
  resetSpvCreationForm: () => set(getInitialValues()),
})
