import { TableLayoutV2 } from 'typ'
import { StoreSlice } from '../types'

export type BaselinesTableField =
  | 'readableId'
  | 'link'
  | 'spvName'
  | 'modifiedBy'
  | 'updatedAt'
  | 'actions'

const initialLayout: TableLayoutV2<BaselinesTableField> = {
  sortParam: 'updatedAt',
  direction: 'descending',
  displayedCols: ['readableId', 'link', 'spvName', 'modifiedBy', 'updatedAt', 'actions'],
}

export interface BaselinesListSlice {
  // TABLE LAYOUT
  baselinesListLayout: TableLayoutV2<BaselinesTableField>
  setBaselinesListLayout: (layout: Partial<TableLayoutV2<BaselinesTableField>>) => void
}
export const createBaselinesListSlice: StoreSlice<BaselinesListSlice> = (set, _get) => ({
  // TABLE LAYOUT

  baselinesListLayout: {
    ...initialLayout,
  },
  setBaselinesListLayout: (layout: Partial<TableLayoutV2<BaselinesTableField>>) =>
    set((state) => ({
      baselinesListLayout: { ...state.baselinesListLayout, ...layout },
    })),
})
