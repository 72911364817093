import { InMemoryCacheConfig } from '@apollo/client'
import possibleTypes from 'generated/tensor-api/possibleTypes.json'

export const tensorReportsCachePolicies: InMemoryCacheConfig = {
  possibleTypes,
  typePolicies: {
    SimulationSummary: {
      merge: true,
    },
    Report: {
      fields: {
        scope: { merge: true },
      },
    },
  },
}
