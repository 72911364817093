import { StoreSlice } from '../types'

export interface AdminUserPreferencesSlice {
  isSideNavCollapsed: boolean
  setIsSideNavCollapsed: (collapsed: boolean) => void
  toggleIsSideNavCollapsed: VoidFunction
}
export const createAdminUserPreferencesSlice: StoreSlice<
  AdminUserPreferencesSlice
> = (set, _get) => ({
  isSideNavCollapsed: false,
  setIsSideNavCollapsed: (collapsed: boolean) =>
    set(() => ({ isSideNavCollapsed: collapsed })),
  toggleIsSideNavCollapsed: () =>
    set((state) => ({ isSideNavCollapsed: !state.isSideNavCollapsed })),
})
