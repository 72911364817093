import { IdNameEntity, Maybe, StyleObject } from 'typ'
import { StoreSlice } from '../types'

type OptionValue = string | number

export type OptionVariant = 'default' | 'danger' | undefined
export type Option<T = OptionValue> = {
  label: string
  value: T
  isEditable?: boolean
  variant?: OptionVariant
  style?: StyleObject
  showDivider?: boolean
  createdAt?: string | Date
}

type CoreUserInfo = {
  firstName: string
  lastName: string
}
export interface WorkspaceSlice {
  currentWorkspace: IdNameEntity | null
  defaultBalancingGroup: IdNameEntity | null
  getCurrentWorkspaceId: () => string
  getCurrentWorkspaceName: () => string
  isDefaultBalancingGroup: (bg: { id: Maybe<string> }) => boolean
  resetWorkspaceSlice: () => void
  setCurrentWorkspace: (org: IdNameEntity) => void
  setDefaultBalancingGroup: (bg: IdNameEntity) => void
  isCurrentWorkspaceBillable: boolean
  setIsCurrentWorkspaceBillable: (isBillable: boolean) => void
  prevWorkspaceOptions: Option<string>[]
  prevWorkspaceId: string
  prevWorkspaceUser: CoreUserInfo
  setPrevWorkspaceOptions: (options: Option<string>[]) => void
  setPrevWorkspaceId: (id: string) => void
  setPrevWorkspaceUser: (user: CoreUserInfo) => void
}

const initialState = {
  currentWorkspace: null,
  defaultBalancingGroup: null,
  isCurrentWorkspaceBillable: false,
  prevWorkspaceUser: {
    firstName: '',
    lastName: '',
    id: '',
  },
  prevWorkspaceOptions: [],
  prevWorkspaceId: '',
}
export const createWorkspaceSlice: StoreSlice<WorkspaceSlice> = (set, get) => ({
  ...initialState,
  setCurrentWorkspace: (org: IdNameEntity) => set(() => ({ currentWorkspace: org })),
  getCurrentWorkspaceId: () => get().currentWorkspace?.id ?? '',
  getCurrentWorkspaceName: () => get().currentWorkspace?.name ?? '',
  setDefaultBalancingGroup: (bg) => set(() => ({ defaultBalancingGroup: bg })),
  isDefaultBalancingGroup: (bg) => {
    const { defaultBalancingGroup } = get()
    if (!defaultBalancingGroup || !bg.id) return false
    return defaultBalancingGroup?.id === bg.id
  },
  setIsCurrentWorkspaceBillable: (isBillable) =>
    set(() => ({ isCurrentWorkspaceBillable: isBillable })),
  setPrevWorkspaceOptions: (options: Option<string>[]) =>
    set(() => ({ prevWorkspaceOptions: options })),
  setPrevWorkspaceId: (id: string) => set(() => ({ prevWorkspaceId: id })),
  setPrevWorkspaceUser: (user: CoreUserInfo) => set(() => ({ prevWorkspaceUser: user })),
  resetWorkspaceSlice: () => set(initialState),
})
