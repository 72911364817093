import { areSameType, isPrimitive } from 'typeguards'

export function descending<T, U>(a: T, b: U) {
  if (!isPrimitive(a) || !isPrimitive(b) || !areSameType(a, b)) return NaN
  return a == null || b == null
    ? NaN
    : b < a
    ? -1
    : b > a
    ? 1
    : b >= a
    ? 0
    : NaN
}
