import { objectFromKeys } from 'object'
import { ReactNode } from 'react'
import { toast as sonnerToast } from 'sonner'
import { isNotNil } from 'typeguards'
import { ToastContents } from './ToastContents'
import { variantKeys } from './constants'
import { ContentProps, Variant } from './types'

const TOAST_ID_PREFIX = 'toast-id-'

function isContentProps(content: ReactNode | ContentProps): content is ContentProps {
  return isNotNil(content) && typeof content === 'object' && 'description' in content
}

const checkForDuplicatesAndHandleToast = (
  content: ReactNode | ContentProps,
  options: {
    toastId: string
    duration?: number
  },
  variant: Variant
) => {
  const filteredProps = isContentProps(content) ? content : { description: content }
  const compositeId = `${TOAST_ID_PREFIX}${options.toastId}`
  const isUpdating = Boolean(document.getElementById(compositeId))
  return sonnerToast.custom(
    (t) => (
      <ToastContents
        id={String(t)}
        variant={variant}
        lastUpdated={isUpdating ? Date.now() : 0}
        {...filteredProps}
      />
    ),
    { id: compositeId, duration: options.duration ?? 5000 }
  )
}

export const toast = {
  // KEEPING THE API LIMITED FOR THE TIME BEING TO AVOID UNNECESSARY COMPLEXITY
  // IF WE NEED TO ADD MORE OPTIONS, EITHER CUSTOM OR FROM SONNER, WE CAN DO SO HERE
  ...objectFromKeys(
    variantKeys,
    (variant) =>
      (content: ReactNode | ContentProps, options: { toastId: string; duration?: number }) =>
        checkForDuplicatesAndHandleToast(content, options, variant)
  ),
}
