import { StyleObject } from 'typ'
import { forms } from './forms'

const WEBSITE_INTERACTIVE_INPUT_STYLES = {
  '&:hover': {
    border: '1px solid',
    borderColor: 'primary',
  },
  '&:focus-within': {
    border: '1px solid',
    borderColor: 'primary',
    outline: 'none',
  },
}
const WEBSITE_BASE_INPUT_STYLES = {
  borderColor: 'steel2',
  maxHeight: '41px',
  height: '41px',
  fontSize: '14px',
  lineHeight: '21px',
  color: 'text',
  py: '10px',
  transition: 'border-color 0.15s ease',
}
export const websiteForms: Record<string, StyleObject> = {
  ...forms,
  label: {
    fontWeight: 300,
    fontSize: '14px',
    lineHeight: '21px',
    color: 'text',
    mb: '4px',
  },
  input: {
    ...forms.input,
    ...WEBSITE_BASE_INPUT_STYLES,
    ...WEBSITE_INTERACTIVE_INPUT_STYLES,
  },
  'input-error': {
    ...forms.input,
    ...WEBSITE_BASE_INPUT_STYLES,
    ...WEBSITE_INTERACTIVE_INPUT_STYLES,
  },
  'input-disabled': {
    ...forms['input-disabled'],
    ...WEBSITE_BASE_INPUT_STYLES,
  },
  textarea: {
    ...forms.textarea,
    borderColor: 'steel2',
    fontSize: '14px',
    lineHeight: '21px',
    color: 'text',
    py: '10px',
    ...WEBSITE_INTERACTIVE_INPUT_STYLES,
  },
}
