import { SortOrder } from './types'

type GenericDate = Date | number | string
type Options = { order?: SortOrder }

export function sortByDate<T extends GenericDate | Record<string, any>>(
  arr: T[],
  options?: Options | null,
  accessor: (el: T) => GenericDate = (el) => el as unknown as GenericDate
): T[] {
  const order = options?.order ?? 'asc'

  return [...arr].sort((a, b) => {
    const valA = new Date(accessor(a) ?? 0).getTime()
    const valB = new Date(accessor(b) ?? 0).getTime()
    return order === 'asc' ? valA - valB : valB - valA
  })
}
