import { StoreSlice } from '../types'

export interface ContactSupportSlice {
  isContactSupportModalOpen: boolean
  setIsContactSupportModalOpen: (isOpen: boolean) => void
  openContactSupportModal: VoidFunction
  closeContactSupportModal: VoidFunction
}

export const createContactSupportSlice: StoreSlice<ContactSupportSlice> = (set, _get) => ({
  isContactSupportModalOpen: false,
  setIsContactSupportModalOpen: (isOpen: boolean) => set({ isContactSupportModalOpen: isOpen }),
  openContactSupportModal: () => set({ isContactSupportModalOpen: true }),
  closeContactSupportModal: () => set({ isContactSupportModalOpen: false }),
})
