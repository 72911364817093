import { TableLayoutV2 } from 'typ'
import { StoreSlice } from '../types'

export type ScenariosTableField = 'readableId' | 'link' | 'description' | 'updatedAt' | 'createdAt'

const initialLayout: TableLayoutV2<ScenariosTableField> = {
  sortParam: 'createdAt',
  direction: 'descending',
  displayedCols: ['readableId', 'link', 'description', 'updatedAt', 'createdAt'],
}

export interface ScenariosListSlice {
  // TABLE LAYOUT
  scenariosListLayout: TableLayoutV2<ScenariosTableField>
  setScenariosListLayout: (layout: Partial<TableLayoutV2<ScenariosTableField>>) => void
}

export const createScenariosListSlice: StoreSlice<ScenariosListSlice> = (set, _get) => ({
  scenariosListLayout: {
    ...initialLayout,
  },
  setScenariosListLayout: (layout: Partial<TableLayoutV2<ScenariosTableField>>) =>
    set((state) => ({
      scenariosListLayout: { ...state.scenariosListLayout, ...layout },
    })),
})
