import { StepVariant } from 'typ'
import {
  PPA_ASSETS_FORM_INITIAL_VALUES,
  PPA_ENTITY_FORM_INITIAL_VALUES,
  NEW_PPA_CREATION_STEPS as STEPS,
} from '../constants'
import {
  NewPpaCreationStepId,
  PpaAssetsFormValues,
  PpaCreationSliceFormValues,
  PpaFormValues,
  StoreSlice,
} from '../types'

type Step = {
  errors: string[]
  variant: StepVariant
  isActive: boolean
  lastSubmitted: number
}

export type PpaCreationDataSlice = PpaCreationSliceFormValues & {
  ppaCreationSteps: Step[]
  isPpaCreationNameTaken: boolean
  isSubmittingPpaCreationForm: boolean
  isPpaCreationComplete: boolean
  ppaCreatedId: string
  setPpaCreatedId: (id: string) => void
  setIsPpaCreationComplete: (value: boolean) => void
  setIsSubmittingPpaCreationForm: (value: boolean) => void
  setPpaSelectedAssets: (assets: string[]) => void
  setIsPpaCreationNameTaken: (value: boolean) => void
  togglePpaCreationStepCompletion: (step: NewPpaCreationStepId) => void
  updatePpaCreationStepErrors: (step: NewPpaCreationStepId, errors: string[]) => void
  selectPpaCreationStep: (step: NewPpaCreationStepId) => void
  resetPpaCreationForm: VoidFunction
  setPpaCreationFormValues: (values: PpaFormValues) => void
  setPpaCreationAssetsValues: (values: PpaAssetsFormValues) => void
  getPpaCreationFormData: () => PpaFormValues
  getPpaCreationActiveStep: () => NewPpaCreationStepId
  updatePpaCreationLastSubmitted: (step: NewPpaCreationStepId, ms?: number) => void
  resetPpaCreationSectionErrors: (step: NewPpaCreationStepId) => void
}

const INITIAL_STEPS: Step[] = [
  {
    errors: [],
    variant: 'in-progress',
    isActive: true,
    lastSubmitted: 0,
  },
  {
    errors: [],
    variant: 'pending',
    isActive: false,
    lastSubmitted: 0,
  },
]

const getInitialValues = () => ({
  isPpaCreationNameTaken: false,
  isSubmittingPpaCreationForm: false,
  isPpaCreationComplete: false,
  ppaCreationSteps: INITIAL_STEPS,
  ppaCreationFormValues: PPA_ENTITY_FORM_INITIAL_VALUES,
  ppaCreationAssetsValues: PPA_ASSETS_FORM_INITIAL_VALUES,
  ppaCreatedId: '',
})

export const createPpaCreationDataSlice: StoreSlice<PpaCreationDataSlice> = (set, get) => ({
  ...getInitialValues(),
  setPpaCreatedId: (id) => set(() => ({ ppaCreatedId: id })),
  setIsPpaCreationComplete: (value: boolean) => set(() => ({ isPpaCreationComplete: value })),
  setPpaSelectedAssets: (assets) => set(() => ({ ppaCreationAssetsValues: { assets } })),
  setIsSubmittingPpaCreationForm: (value: boolean) =>
    set(() => ({ isSubmittingPpaCreationForm: value })),
  setIsPpaCreationNameTaken: (value: boolean) => set(() => ({ isPpaCreationNameTaken: value })),
  togglePpaCreationStepCompletion: (step) => {
    const state = get()
    if (state.isSubmittingPpaCreationForm || state.isPpaCreationComplete) return
    const stepIndex = STEPS.indexOf(step)
    const steps: Step[] = state.ppaCreationSteps.map((s, i) => {
      if (i <= stepIndex) {
        return {
          ...s,
          variant: 'completed',
          isActive: false,
          hasSumbitted: true,
        }
      }
      if (i === stepIndex + 1) {
        return {
          ...s,
          variant: s.variant === 'pending' ? 'in-progress' : s.variant,
          isActive: true,
        }
      }
      return s
    })
    set(() => ({ ppaCreationSteps: steps }))
  },
  updatePpaCreationStepErrors: (step, errors) => {
    const state = get()
    const stepIndex = STEPS.indexOf(step)
    const lastTouchedStep = state.ppaCreationSteps.findIndex((s) => s.variant === 'in-progress')
    const steps: Step[] = state.ppaCreationSteps.map((s, i) => {
      if (i === stepIndex) {
        return {
          ...s,
          errors,
          variant: errors.length
            ? 'error'
            : lastTouchedStep > stepIndex
              ? 'completed'
              : 'in-progress',
        }
      }
      return s
    })
    set(() => ({ ppaCreationSteps: steps }))
  },
  selectPpaCreationStep: (step) => {
    const state = get()
    if (state.isSubmittingPpaCreationForm || state.isPpaCreationComplete) return
    const stepIndex = STEPS.indexOf(step)
    const currentStepIndex = state.ppaCreationSteps.findIndex((s) => s.isActive)
    const steps: Step[] = state.ppaCreationSteps.map((s, i) => ({
      ...s,
      isActive: i === stepIndex,
      variant:
        i === currentStepIndex && !s.errors.length && stepIndex > currentStepIndex
          ? 'completed'
          : s.variant,
    }))
    set(() => ({
      ppaCreationSteps: steps,
    }))
  },
  updatePpaCreationLastSubmitted: (step, ms = Date.now()) => {
    const state = get()
    const stepIndex = STEPS.indexOf(step)
    const steps: Step[] = state.ppaCreationSteps.map((s, i) => ({
      ...s,
      lastSubmitted: i === stepIndex ? ms : s.lastSubmitted,
    }))
    set(() => ({ ppaCreationSteps: steps }))
  },
  setPpaCreationFormValues: (values) => set(() => ({ ppaCreationFormValues: values })),
  setPpaCreationAssetsValues: (values) => set(() => ({ ppaCreationAssetsValues: values })),
  getPpaCreationFormData: () => {
    const state = get()
    return {
      ...state.ppaCreationFormValues,
      ...state.ppaCreationAssetsValues,
    }
  },
  getPpaCreationActiveStep: () => {
    const state = get()
    return STEPS[state.ppaCreationSteps.findIndex((s) => s.isActive)]
  },
  resetPpaCreationSectionErrors: (step) => {
    const state = get()
    state.updatePpaCreationStepErrors(step, [])
  },
  resetPpaCreationForm: () => set(getInitialValues()),
})
