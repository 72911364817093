import { GetState, SetState } from 'zustand'
import { createWebsiteFipCalculatorSlice } from '../slices'

export const createPersistedStoreSlice = (set: SetState<any>, get: GetState<any>) => ({
  ...createWebsiteFipCalculatorSlice(set, get),
})
export type TensorWebsitePersistedSlice = ReturnType<typeof createPersistedStoreSlice>

export const createSessionStoreSlice = (_set: SetState<any>, _get: GetState<any>) => ({})

export type TensorWebsiteSessionSlice = ReturnType<typeof createSessionStoreSlice>
