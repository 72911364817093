const primitives = {
  Tensor: {
    '1': '#fcfdfe',
    '2': '#f6faff',
    '3': '#eaf2ff',
    '4': '#dceaff',
    '5': '#cbe0ff',
    '6': '#b3d3ff',
    '7': '#9bc0ff',
    '8': '#77a7ff',
    '9': '#0c5cf3',
    '10': '#0b51d6',
    '11': '#165ae0',
    '12': '#102d66',
  },
  Tomato: {
    '1': '#fffcfc',
    '2': '#fff8f7',
    '3': '#fff0ee',
    '4': '#ffe6e2',
    '5': '#fdd8d3',
    '6': '#fac7be',
    '7': '#f3b0a2',
    '8': '#ea9280',
    '9': '#e54d2e',
    '10': '#d84224',
    '11': '#c33113',
    '12': '#5c271f',
  },
  Red: {
    '1': '#fffcfc',
    '2': '#fff7f7',
    '3': '#ffefef',
    '4': '#ffe5e5',
    '5': '#fdd8d8',
    '6': '#f9c6c6',
    '7': '#f3aeaf',
    '8': '#eb9091',
    '9': '#e5484d',
    '10': '#d93d42',
    '11': '#c62a2f',
    '12': '#641723',
  },
  Crimson: {
    '1': '#fffcfd',
    '2': '#fff7fb',
    '3': '#feeff6',
    '4': '#fce5f0',
    '5': '#f9d8e7',
    '6': '#f4c6db',
    '7': '#edadc8',
    '8': '#e58fb1',
    '9': '#e93d82',
    '10': '#dc3175',
    '11': '#cb1d63',
    '12': '#621639',
  },
  Pink: {
    '1': '#fffcfe',
    '2': '#fff7fc',
    '3': '#feeef8',
    '4': '#fce5f3',
    '5': '#f9d8ec',
    '6': '#f3c6e2',
    '7': '#ecadd4',
    '8': '#e38ec3',
    '9': '#d6409f',
    '10': '#cd3093',
    '11': '#c41c87',
    '12': '#651249',
  },
  Plum: {
    '1': '#fefcff',
    '2': '#fff8ff',
    '3': '#fceffc',
    '4': '#f9e5f9',
    '5': '#f3d9f4',
    '6': '#ebc8ed',
    '7': '#dfafe3',
    '8': '#cf91d8',
    '9': '#ab4aba',
    '10': '#a43cb4',
    '11': '#9c2bad',
    '12': '#53195d',
  },
  Purple: {
    '1': '#fefcfe',
    '2': '#fdfaff',
    '3': '#f9f1fe',
    '4': '#f3e7fc',
    '5': '#eddbf9',
    '6': '#e3ccf4',
    '7': '#d3b4ed',
    '8': '#be93e4',
    '9': '#8e4ec6',
    '10': '#8445bc',
    '11': '#793aaf',
    '12': '#402060',
  },
  Violet: {
    '1': '#fdfcfe',
    '2': '#fbfaff',
    '3': '#f5f2ff',
    '4': '#ede9fe',
    '5': '#e4defc',
    '6': '#d7cff9',
    '7': '#c4b8f3',
    '8': '#aa99ec',
    '9': '#6e56cf',
    '10': '#644fc1',
    '11': '#5746af',
    '12': '#2f265f',
  },
  Indigo: {
    '1': '#fdfdfe',
    '2': '#f8faff',
    '3': '#f0f4ff',
    '4': '#e6edfe',
    '5': '#d9e2fc',
    '6': '#c6d4f9',
    '7': '#aec0f5',
    '8': '#8da4ef',
    '9': '#3e63dd',
    '10': '#3a5ccc',
    '11': '#3451b2',
    '12': '#101d46',
  },
  Cyan: {
    '1': '#fafdfe',
    '2': '#f2fcfd',
    '3': '#e7f9fb',
    '4': '#d8f3f6',
    '5': '#c4eaef',
    '6': '#aadee6',
    '7': '#84cdda',
    '8': '#3db9cf',
    '9': '#05a2c2',
    '10': '#0894b3',
    '11': '#0c7792',
    '12': '#0d3c48',
  },
  Teal: {
    '1': '#fafefd',
    '2': '#f1fcfa',
    '3': '#e7f9f5',
    '4': '#d9f3ee',
    '5': '#c7ebe5',
    '6': '#afdfd7',
    '7': '#8dcec3',
    '8': '#53b9ab',
    '9': '#12a594',
    '10': '#0e9888',
    '11': '#067a6f',
    '12': '#0d3d38',
  },
  Green: {
    '1': '#fbfefc',
    '2': '#f2fcf5',
    '3': '#e9f9ee',
    '4': '#ddf3e4',
    '5': '#ccebd7',
    '6': '#b4dfc4',
    '7': '#92ceac',
    '8': '#5bb98c',
    '9': '#30a46c',
    '10': '#299764',
    '11': '#18794e',
    '12': '#193b2d',
  },
  Grass: {
    '1': '#fbfefb',
    '2': '#f3fcf3',
    '3': '#ebf9eb',
    '4': '#dff3df',
    '5': '#ceebcf',
    '6': '#b7dfba',
    '7': '#97cf9c',
    '8': '#65ba75',
    '9': '#46a758',
    '10': '#3d9a50',
    '11': '#297c3b',
    '12': '#203c25',
  },
  Orange: {
    '1': '#fefcfb',
    '2': '#fff8f4',
    '3': '#ffedd5',
    '4': '#ffe0bb',
    '5': '#ffd3a4',
    '6': '#ffc291',
    '7': '#ffaa7d',
    '8': '#ed8a5c',
    '9': '#f76808',
    '10': '#ed5f00',
    '11': '#99543a',
    '12': '#582d1d',
  },
  Brown: {
    '1': '#fefdfc',
    '2': '#fcf9f6',
    '3': '#f8f1ea',
    '4': '#f4e9dd',
    '5': '#efddcc',
    '6': '#e8cdb5',
    '7': '#ddb896',
    '8': '#d09e72',
    '9': '#ad7f58',
    '10': '#9e7352',
    '11': '#815e46',
    '12': '#3e332e',
  },
  TensorAlpha: {
    '1': 'rgba(0, 85, 170, 0.0118)',
    '2': 'rgba(0, 114, 255, 0.0353)',
    '3': 'rgba(0, 98, 255, 0.0824)',
    '4': 'rgba(0, 102, 255, 0.1373)',
    '5': 'rgba(0, 103, 255, 0.2039)',
    '6': 'rgba(0, 108, 255, 0.2980)',
    '7': 'rgba(0, 95, 255, 0.3922)',
    '8': 'rgba(0, 90, 255, 0.5333)',
    '9': 'rgba(0, 84, 242, 0.9529)',
    '10': 'rgba(0, 73, 212, 0.9569)',
    '11': 'rgba(0, 74, 221, 0.9137)',
    '12': 'rgba(0, 31, 92, 0.9373)',
  },
  TomatoAlpha: {
    '1': 'rgba(255, 5, 5, 0.0120)',
    '2': 'rgba(255, 36, 5, 0.0320)',
    '3': 'rgba(255, 31, 1, 0.0670)',
    '4': 'rgba(255, 36, 1, 0.1140)',
    '5': 'rgba(244, 30, 1, 0.1730)',
    '6': 'rgba(236, 35, 0, 0.2550)',
    '7': 'rgba(222, 39, 0, 0.3650)',
    '8': 'rgba(213, 37, 1, 0.4990)',
    '9': 'rgba(223, 38, 0, 0.8200)',
    '10': 'rgba(210, 36, 0, 0.8590)',
    '11': 'rgba(190, 31, 0, 0.9260)',
    '12': 'rgba(69, 9, 0, 0.8790)',
  },
  PurpleAlpha: {
    '1': 'rgba(171, 5, 171, 0.0120)',
    '2': 'rgba(155, 5, 255, 0.0200)',
    '3': 'rgba(146, 0, 237, 0.0550)',
    '4': 'rgba(129, 2, 224, 0.0950)',
    '5': 'rgba(128, 1, 213, 0.1420)',
    '6': 'rgba(115, 0, 200, 0.2000)',
    '7': 'rgba(106, 1, 194, 0.2950)',
    '8': 'rgba(102, 0, 191, 0.4240)',
    '9': 'rgba(92, 0, 173, 0.6950)',
    '10': 'rgba(86, 0, 163, 0.7300)',
    '11': 'rgba(82, 0, 151, 0.7730)',
    '12': 'rgba(37, 0, 73, 0.8750)',
  },
  VioletAlpha: {
    '1': 'rgba(88, 5, 171, 0.0120)',
    '2': 'rgba(55, 5, 255, 0.0200)',
    '3': 'rgba(59, 0, 255, 0.0510)',
    '4': 'rgba(46, 2, 244, 0.0870)',
    '5': 'rgba(47, 1, 232, 0.1300)',
    '6': 'rgba(43, 1, 223, 0.1890)',
    '7': 'rgba(44, 1, 212, 0.2790)',
    '8': 'rgba(43, 0, 208, 0.4000)',
    '9': 'rgba(36, 0, 182, 0.6630)',
    '10': 'rgba(31, 0, 165, 0.6910)',
    '11': 'rgba(24, 0, 145, 0.7260)',
    '12': 'rgba(10, 0, 67, 0.8510)',
  },
  IndigoAlpha: {
    '1': 'rgba(5, 5, 130, 0.0080)',
    '2': 'rgba(5, 77, 255, 0.0280)',
    '3': 'rgba(1, 68, 255, 0.0590)',
    '4': 'rgba(2, 73, 245, 0.0990)',
    '5': 'rgba(2, 62, 235, 0.1500)',
    '6': 'rgba(1, 63, 228, 0.2240)',
    '7': 'rgba(0, 57, 224, 0.3180)',
    '8': 'rgba(1, 52, 219, 0.4480)',
    '9': 'rgba(0, 49, 210, 0.7570)',
    '10': 'rgba(0, 44, 189, 0.7730)',
    '11': 'rgba(0, 37, 158, 0.7970)',
    '12': 'rgba(0, 17, 70, 0.8790)',
  },
  BlueAlpha: {
    '1': 'rgba(5, 130, 255, 0.0160)',
    '2': 'rgba(5, 130, 255, 0.0400)',
    '3': 'rgba(2, 128, 255, 0.0710)',
    '4': 'rgba(1, 125, 250, 0.1930)',
    '5': 'rgba(1, 131, 250, 0.1930)',
    '6': 'rgba(1, 121, 230, 0.2830)',
    '7': 'rgba(0, 119, 223, 0.4120)',
    '8': 'rgba(0, 130, 230, 0.6320)',
    '9': '#0091ff',
    '10': 'rgba(0, 125, 234, 0.9690)',
    '11': 'rgba(0, 97, 201, 0.9570)',
    '12': 'rgba(0, 36, 89, 0.9340)',
  },
  CyanAlpha: {
    '1': 'rgba(5, 155, 205, 0.0200)',
    '2': 'rgba(0, 196, 216, 0.0510)',
    '3': 'rgba(2, 192, 213, 0.0950)',
    '4': 'rgba(0, 177, 196, 0.1530)',
    '5': 'rgba(1, 164, 186, 0.2320)',
    '6': 'rgba(1, 156, 180, 0.3340)',
    '7': 'rgba(0, 151, 178, 0.4830)',
    '8': 'rgba(0, 163, 192, 0.7610)',
    '9': 'rgba(0, 160, 193, 0.9810)',
    '10': 'rgba(0, 144, 176, 0.9690)',
    '11': 'rgba(0, 112, 141, 0.9530)',
    '12': 'rgba(0, 50, 62, 0.9500)',
  },
  TealAlpha: {
    '1': 'rgba(5, 205, 155, 0.0200)',
    '2': 'rgba(1, 200, 164, 0.0550)',
    '3': 'rgba(2, 192, 150, 0.0950)',
    '4': 'rgba(2, 175, 141, 0.1500)',
    '5': 'rgba(0, 164, 137, 0.2200)',
    '6': 'rgba(0, 153, 128, 0.3140)',
    '7': 'rgba(1, 146, 121, 0.4480)',
    '8': 'rgba(0, 151, 131, 0.6750)',
    '9': 'rgba(0, 158, 140, 0.9300)',
    '10': 'rgba(0, 146, 129, 0.9460)',
    '11': 'rgba(0, 119, 107, 0.9770)',
    '12': 'rgba(0, 51, 46, 0.9500)',
  },
  GreenAlpha: {
    '1': 'rgba(5, 192, 67, 0.0160)',
    '2': 'rgba(2, 186, 60, 0.0870)',
    '3': 'rgba(2, 186, 60, 0.0870)',
    '4': 'rgba(1, 166, 54, 0.1340)',
    '5': 'rgba(0, 155, 55, 0.2000)',
    '6': 'rgba(1, 147, 55, 0.2950)',
    '7': 'rgba(0, 140, 61, 0.4280)',
    '8': 'rgba(0, 147, 77, 0.6440)',
    '9': 'rgba(0, 143, 74, 0.8120)',
    '10': 'rgba(0, 131, 71, 0.8400)',
    '11': 'rgba(0, 107, 59, 0.9060)',
    '12': 'rgba(0, 38, 22, 0.9020)',
  },
  GrassAlpha: {
    '1': 'rgba(5, 192, 5, 0.0160)',
    '2': 'rgba(5, 192, 5, 0.0480)',
    '3': 'rgba(2, 179, 2, 0.0790)',
    '4': 'rgba(1, 160, 1, 0.1260)',
    '5': 'rgba(1, 151, 6, 0.1930)',
    '6': 'rgba(1, 142, 11, 0.2830)',
    '7': 'rgba(0, 138, 12, 0.4080)',
    '8': 'rgba(0, 141, 26, 0.6040)',
    '9': 'rgba(0, 134, 25, 0.7260)',
    '10': 'rgba(0, 122, 25, 0.7610)',
    '11': 'rgba(0, 99, 22, 0.8400)',
    '12': 'rgba(0, 32, 6, 0.8750)',
  },
  OrangeAlpha: {
    '1': 'rgba(192, 67, 5, 0.0160)',
    '2': 'rgba(255, 97, 5, 0.0440)',
    '3': 'rgba(255, 145, 1, 0.1650)',
    '4': 'rgba(255, 140, 1, 0.2670)',
    '5': 'rgba(255, 132, 0, 0.3570)',
    '6': 'rgba(255, 115, 1, 0.4320)',
    '7': 'rgba(255, 90, 1, 0.5110)',
    '8': 'rgba(227, 72, 0, 0.6400)',
    '9': 'rgba(247, 99, 0, 0.9690)',
    '10': '#ed5f00',
    '11': 'rgba(123, 33, 0, 0.7730)',
    '12': 'rgba(67, 18, 0, 0.8870)',
  },
  BrownAlpha: {
    '1': 'rgba(171, 88, 5, 0.0120)',
    '2': 'rgba(171, 88, 5, 0.0360)',
    '3': 'rgba(171, 86, 2, 0.0830)',
    '4': 'rgba(173, 91, 1, 0.1340)',
    '5': 'rgba(175, 85, 0, 0.2000)',
    '6': 'rgba(176, 83, 1, 0.2910)',
    '7': 'rgba(172, 83, 0, 0.4120)',
    '8': 'rgba(170, 79, 0, 0.5530)',
    '9': 'rgba(130, 60, 0, 0.6550)',
    '10': 'rgba(112, 49, 0, 0.6790)',
    '11': 'rgba(81, 33, 0, 0.7260)',
    '12': 'rgba(20, 6, 0, 0.8200)',
  },
  RedAlpha: {
    '1': 'rgba(255, 5, 5, 0.0120)',
    '2': 'rgba(255, 5, 5, 0.0320)',
    '3': 'rgba(255, 1, 1, 0.0630)',
    '4': 'rgba(255, 0, 0, 0.1020)',
    '5': 'rgba(242, 0, 0, 0.1530)',
    '6': 'rgba(228, 1, 1, 0.2240)',
    '7': 'rgba(217, 0, 3, 0.3180)',
    '8': 'rgba(209, 0, 3, 0.4360)',
    '9': 'rgba(219, 0, 7, 0.7180)',
    '10': 'rgba(205, 0, 8, 0.7610)',
    '11': 'rgba(187, 0, 7, 0.8360)',
    '12': 'rgba(85, 0, 13, 0.9100)',
  },
  CrimsonAlpha: {
    '1': 'rgba(255, 5, 88, 0.0120)',
    '2': 'rgba(255, 5, 130, 0.0320)',
    '3': 'rgba(255, 5, 122, 0.0320)',
    '4': 'rgba(226, 0, 108, 0.1020)',
    '5': 'rgba(216, 0, 98, 0.1530)',
    '6': 'rgba(206, 1, 94, 0.2240)',
    '7': 'rgba(199, 0, 84, 0.3220)',
    '8': 'rgba(196, 0, 78, 0.4400)',
    '9': 'rgba(226, 0, 91, 0.7610)',
    '10': 'rgba(212, 0, 85, 0.8080)',
    '11': 'rgba(196, 0, 78, 0.8870)',
    '12': 'rgba(83, 0, 39, 0.9140)',
  },
  PinkAlpha: {
    '1': 'rgba(255, 5, 172, 0.0120)',
    '2': 'rgba(255, 5, 161, 0.0320)',
    '3': 'rgba(240, 1, 150, 0.0670)',
    '4': 'rgba(226, 0, 137, 0.1020)',
    '5': 'rgba(216, 0, 131, 0.1530)',
    '6': 'rgba(201, 1, 126, 0.2240)',
    '7': 'rgba(196, 0, 121, 0.3220)',
    '8': 'rgba(192, 0, 120, 0.4440)',
    '9': 'rgba(200, 0, 127, 0.7500)',
    '10': 'rgba(193, 0, 122, 0.8120)',
    '11': 'rgba(189, 0, 120, 0.8910)',
    '12': 'rgba(90, 0, 60, 0.9300)',
  },
  PlumAlpha: {
    '1': 'rgba(172, 5, 255, 0.0120)',
    '2': 'rgba(255, 5, 255, 0.0280)',
    '3': 'rgba(208, 1, 208, 0.0630)',
    '4': 'rgba(196, 0, 196, 0.1020)',
    '5': 'rgba(175, 2, 181, 0.1500)',
    '6': 'rgba(162, 0, 172, 0.2160)',
    '7': 'rgba(153, 0, 166, 0.3140)',
    '8': 'rgba(144, 0, 165, 0.4320)',
    '9': 'rgba(137, 0, 158, 0.7100)',
    '10': 'rgba(136, 0, 157, 0.7650)',
    '11': 'rgba(136, 0, 156, 0.8320)',
    '12': 'rgba(64, 0, 75, 0.9020)',
  },
  Sky: {
    '1': '#f9feff',
    '2': '#f1fcff',
    '3': '#e2f9ff',
    '4': '#d2f4fd',
    '5': '#bfebf8',
    '6': '#a5dced',
    '7': '#82cae0',
    '8': '#46b8d8',
    '9': '#7ce2fe',
    '10': '#72dbf8',
    '11': '#256e93',
    '12': '#19404d',
  },
  Mint: {
    '1': '#f9fefd',
    '2': '#effefa',
    '3': '#ddfbf3',
    '4': '#ccf7ec',
    '5': '#bbeee2',
    '6': '#a6e1d3',
    '7': '#87d0bf',
    '8': '#51bda7',
    '9': '#86ead4',
    '10': '#7fe1cc',
    '11': '#27756a',
    '12': '#16433c',
  },
  Lime: {
    '1': '#fcfdfa',
    '2': '#f7fcf0',
    '3': '#edfada',
    '4': '#e2f5c4',
    '5': '#d5edaf',
    '6': '#c6de99',
    '7': '#b2ca7f',
    '8': '#9ab654',
    '9': '#bdee63',
    '10': '#b0e64c',
    '11': '#59682c',
    '12': '#37401c',
  },
  Yellow: {
    '1': '#fdfdf9',
    '2': '#fffbe0',
    '3': '#fff8c6',
    '4': '#fcf3af',
    '5': '#f7ea9b',
    '6': '#ecdd85',
    '7': '#dac56e',
    '8': '#c9aa45',
    '9': '#fbe32d',
    '10': '#f9da10',
    '11': '#775f28',
    '12': '#473b1f',
  },
  Amber: {
    '1': '#fefdfb',
    '2': '#fff9ed',
    '3': '#fff3d0',
    '4': '#ffecb7',
    '5': '#ffe0a1',
    '6': '#f5d08c',
    '7': '#e4bb78',
    '8': '#d6a35c',
    '9': '#ffc53d',
    '10': '#ffba1a',
    '11': '#915930',
    '12': '#4f3422',
  },
  SkyAlpha: {
    '1': 'rgba(5, 213, 255, 0.0240)',
    '2': 'rgba(1, 200, 255, 0.0550)',
    '3': 'rgba(1, 204, 255, 0.1140)',
    '4': 'rgba(1, 191, 244, 0.1770)',
    '5': 'rgba(0, 174, 227, 0.2510)',
    '6': 'rgba(0, 156, 204, 0.3530)',
    '7': 'rgba(0, 146, 192, 0.4910)',
    '8': 'rgba(0, 157, 201, 0.7260)',
    '9': 'rgba(0, 199, 253, 0.5140)',
    '10': 'rgba(0, 190, 242, 0.5530)',
    '11': 'rgba(0, 86, 129, 0.8550)',
    '12': 'rgba(0, 44, 58, 0.9020)',
  },
  MintAlpha: {
    '1': 'rgba(5, 213, 172, 0.0240)',
    '2': 'rgba(1, 239, 176, 0.0630)',
    '3': 'rgba(1, 225, 165, 0.1340)',
    '4': 'rgba(0, 215, 161, 0.2000)',
    '5': 'rgba(0, 192, 147, 0.2670)',
    '6': 'rgba(1, 169, 130, 0.3500)',
    '7': 'rgba(0, 155, 120, 0.4710)',
    '8': 'rgba(0, 158, 127, 0.6830)',
    '9': 'rgba(0, 211, 165, 0.4750)',
    '10': 'rgba(0, 195, 153, 0.5020)',
    '11': 'rgba(0, 92, 80, 0.8480)',
    '12': 'rgba(0, 49, 42, 0.9140)',
  },
  LimeAlpha: {
    '1': 'rgba(105, 155, 5, 0.0200)',
    '2': 'rgba(119, 204, 1, 0.0590)',
    '3': 'rgba(133, 222, 1, 0.1460)',
    '4': 'rgba(131, 212, 1, 0.2320)',
    '5': 'rgba(122, 198, 0, 0.3140)',
    '6': 'rgba(112, 172, 0, 0.4000)',
    '7': 'rgba(103, 149, 0, 0.5020)',
    '8': 'rgba(105, 146, 0, 0.6710)',
    '9': 'rgba(148, 227, 0, 0.6120)',
    '10': 'rgba(143, 219, 0, 0.7020)',
    '11': 'rgba(55, 73, 0, 0.8280)',
    '12': 'rgba(30, 41, 0, 0.8910)',
  },
  YellowAlpha: {
    '1': 'rgba(171, 171, 5, 0.0240)',
    '2': 'rgba(255, 221, 1, 0.1220)',
    '3': 'rgba(255, 227, 1, 0.2240)',
    '4': 'rgba(246, 217, 0, 0.3140)',
    '5': 'rgba(235, 203, 1, 0.3930)',
    '6': 'rgba(215, 183, 0, 0.4790)',
    '7': 'rgba(190, 152, 0, 0.5690)',
    '8': 'rgba(181, 139, 0, 0.7300)',
    '9': 'rgba(250, 220, 0, 0.8240)',
    '10': 'rgba(249, 216, 0, 0.9380)',
    '11': 'rgba(94, 66, 0, 0.8440)',
    '12': 'rgba(46, 32, 0, 0.8790)',
  },
  AmberAlpha: {
    '1': 'rgba(192, 130, 5, 0.0160)',
    '2': 'rgba(255, 170, 1, 0.0710)',
    '3': 'rgba(255, 191, 1, 0.1850)',
    '4': 'rgba(255, 187, 1, 0.2830)',
    '5': 'rgba(254, 170, 1, 0.3690)',
    '6': 'rgba(233, 151, 0, 0.4510)',
    '7': 'rgba(204, 126, 0, 0.5300)',
    '8': 'rgba(191, 112, 0, 0.6400)',
    '9': 'rgba(255, 178, 0, 0.7610)',
    '10': 'rgba(255, 179, 1, 0.8990)',
    '11': 'rgba(120, 50, 0, 0.8120)',
    '12': 'rgba(52, 21, 0, 0.8670)',
  },
  Gray: {
    '1': '#fcfcfc',
    '2': '#f9f9f9',
    '3': '#f1f1f1',
    '4': '#ebebeb',
    '5': '#e4e4e4',
    '6': '#dddddd',
    '7': '#d4d4d4',
    '8': '#bbbbbb',
    '9': '#8d8d8d',
    '10': '#808080',
    '11': '#646464',
    '12': '#202020',
  },
  Mauve: {
    '1': '#fdfcfd',
    '2': '#faf9fb',
    '3': '#f3f1f5',
    '4': '#eceaef',
    '5': '#e6e3e9',
    '6': '#dfdce3',
    '7': '#d5d3db',
    '8': '#bcbac7',
    '9': '#8e8c99',
    '10': '#817f8b',
    '11': '#65636d',
    '12': '#211f26',
  },
  Slate: {
    '1': '#fcfcfd',
    '2': '#f9f9fb',
    '3': '#f2f2f5',
    '4': '#ebebef',
    '5': '#e4e4e9',
    '6': '#dddde3',
    '7': '#d3d4db',
    '8': '#b9bbc6',
    '9': '#8b8d98',
    '10': '#7e808a',
    '11': '#60646c',
    '12': '#1c2024',
  },
  Sage: {
    '1': '#fbfdfc',
    '2': '#f7f9f8',
    '3': '#f0f2f1',
    '4': '#e9eceb',
    '5': '#e3e6e4',
    '6': '#dcdfdd',
    '7': '#d2d5d3',
    '8': '#b8bcba',
    '9': '#868e8b',
    '10': '#7a817f',
    '11': '#5f6563',
    '12': '#1a211e',
  },
  Olive: {
    '1': '#fcfdfc',
    '2': '#f8faf8',
    '3': '#f1f3f1',
    '4': '#eaecea',
    '5': '#e3e5e3',
    '6': '#dbdedb',
    '7': '#d2d4d1',
    '8': '#b9bcb8',
    '9': '#898e87',
    '10': '#7c817b',
    '11': '#60655f',
    '12': '#1d211c',
  },
  Sand: {
    '1': '#fdfdfc',
    '2': '#f9f9f8',
    '3': '#f2f2f0',
    '4': '#ebebe9',
    '5': '#e4e4e2',
    '6': '#ddddda',
    '7': '#d3d2ce',
    '8': '#bcbbb5',
    '9': '#8d8d86',
    '10': '#80807a',
    '11': '#63635e',
    '12': '#21201c',
  },
  GrayAlpha: {
    '1': 'rgba(0, 0, 0, 0.0120)',
    '2': 'rgba(0, 0, 0, 0.0240)',
    '3': 'rgba(0, 0, 0, 0.0550)',
    '4': 'rgba(0, 0, 0, 0.0780)',
    '5': 'rgba(0, 0, 0, 0.1060)',
    '6': 'rgba(0, 0, 0, 0.1330)',
    '7': 'rgba(0, 0, 0, 0.1690)',
    '8': 'rgba(0, 0, 0, 0.2670)',
    '9': 'rgba(0, 0, 0, 0.4470)',
    '10': 'rgba(0, 0, 0, 0.4980)',
    '11': 'rgba(0, 0, 0, 0.6080)',
    '12': 'rgba(0, 0, 0, 0.8750)',
  },
  MauveAlpha: {
    '1': 'rgba(88, 5, 88, 0.0120)',
    '2': 'rgba(47, 5, 88, 0.0240)',
    '3': 'rgba(37, 0, 73, 0.0550)',
    '4': 'rgba(26, 2, 62, 0.0830)',
    '5': 'rgba(28, 0, 55, 0.1100)',
    '6': 'rgba(23, 1, 52, 0.1380)',
    '7': 'rgba(12, 1, 47, 0.1730)',
    '8': 'rgba(8, 0, 49, 0.2710)',
    '9': 'rgba(4, 0, 29, 0.4510)',
    '10': 'rgba(4, 0, 24, 0.5020)',
    '11': 'rgba(3, 0, 16, 0.6120)',
    '12': 'rgba(2, 0, 8, 0.8790)',
  },
  SlateAlpha: {
    '1': 'rgba(5, 5, 88, 0.0120)',
    '2': 'rgba(5, 5, 88, 0.0240)',
    '3': 'rgba(0, 0, 59, 0.0510)',
    '4': 'rgba(2, 2, 52, 0.0790)',
    '5': 'rgba(0, 0, 48, 0.1060)',
    '6': 'rgba(1, 1, 46, 0.1340)',
    '7': 'rgba(1, 6, 47, 0.1730)',
    '8': 'rgba(0, 8, 47, 0.2750)',
    '9': 'rgba(0, 4, 29, 0.4550)',
    '10': 'rgba(0, 4, 24, 0.5060)',
    '11': 'rgba(0, 7, 19, 0.6240)',
    '12': 'rgba(0, 5, 9, 0.8910)',
  },
  SageAlpha: {
    '1': 'rgba(5, 130, 68, 0.0160)',
    '2': 'rgba(5, 69, 37, 0.0320)',
    '3': 'rgba(1, 34, 18, 0.0590)',
    '4': 'rgba(2, 37, 25, 0.0870)',
    '5': 'rgba(0, 28, 9, 0.1100)',
    '6': 'rgba(1, 23, 9, 0.1380)',
    '7': 'rgba(1, 18, 6, 0.1770)',
    '8': 'rgba(1, 15, 8, 0.2790)',
    '9': 'rgba(0, 17, 11, 0.4750)',
    '10': 'rgba(0, 14, 10, 0.5220)',
    '11': 'rgba(0, 10, 7, 0.6280)',
    '12': 'rgba(0, 8, 5, 0.8990)',
  },
  OliveAlpha: {
    '1': 'rgba(5, 88, 5, 0.0120)',
    '2': 'rgba(5, 77, 5, 0.0280)',
    '3': 'rgba(0, 37, 0, 0.0550)',
    '4': 'rgba(2, 26, 2, 0.0830)',
    '5': 'rgba(0, 18, 0, 0.1100)',
    '6': 'rgba(1, 22, 1, 0.1420)',
    '7': 'rgba(6, 17, 1, 0.1810)',
    '8': 'rgba(4, 15, 1, 0.2790)',
    '9': 'rgba(4, 15, 0, 0.4710)',
    '10': 'rgba(2, 12, 0, 0.5180)',
    '11': 'rgba(2, 10, 0, 0.6280)',
    '12': 'rgba(1, 6, 0, 0.8910)',
  },
  SandAlpha: {
    '1': 'rgba(88, 88, 5, 0.0120)',
    '2': 'rgba(41, 41, 5, 0.0280)',
    '3': 'rgba(34, 34, 1, 0.0590)',
    '4': 'rgba(25, 25, 2, 0.0870)',
    '5': 'rgba(18, 18, 1, 0.1140)',
    '6': 'rgba(22, 22, 2, 0.1460)',
    '7': 'rgba(27, 22, 1, 0.1930)',
    '8': 'rgba(25, 21, 1, 0.2910)',
    '9': 'rgba(15, 15, 0, 0.4750)',
    '10': 'rgba(12, 12, 0, 0.5220)',
    '11': 'rgba(8, 8, 0, 0.6320)',
    '12': 'rgba(6, 5, 0, 0.8910)',
  },
  Bronze: {
    '1': '#fdfcfc',
    '2': '#fdf8f6',
    '3': '#f8f1ee',
    '4': '#f2e8e4',
    '5': '#eaddd7',
    '6': '#e0cec7',
    '7': '#d1b9b0',
    '8': '#bfa094',
    '9': '#a18072',
    '10': '#947467',
    '11': '#7d5e54',
    '12': '#43302b',
  },
  Gold: {
    '1': '#fdfdfc',
    '2': '#fbf9f2',
    '3': '#f5f2e9',
    '4': '#eeeadd',
    '5': '#e5dfd0',
    '6': '#dad1bd',
    '7': '#cbbda4',
    '8': '#b8a383',
    '9': '#978365',
    '10': '#89775c',
    '11': '#71624b',
    '12': '#3b352b',
  },
  BronzeAlpha: {
    '1': 'rgba(88, 5, 5, 0.0120)',
    '2': 'rgba(199, 61, 5, 0.0360)',
    '3': 'rgba(151, 46, 1, 0.0670)',
    '4': 'rgba(132, 38, 0, 0.1060)',
    '5': 'rgba(121, 38, 0, 0.1570)',
    '6': 'rgba(114, 32, 0, 0.2200)',
    '7': 'rgba(110, 33, 0, 0.3100)',
    '8': 'rgba(103, 29, 0, 0.4200)',
    '9': 'rgba(85, 25, 0, 0.5530)',
    '10': 'rgba(76, 22, 0, 0.5970)',
    '11': 'rgba(61, 15, 0, 0.6710)',
    '12': 'rgba(29, 6, 0, 0.8320)',
  },
  GoldAlpha: {
    '1': 'rgba(88, 88, 5, 0.0120)',
    '2': 'rgba(176, 137, 0, 0.0510)',
    '3': 'rgba(140, 106, 2, 0.0870)',
    '4': 'rgba(128, 98, 1, 0.1340)',
    '5': 'rgba(114, 82, 1, 0.1850)',
    '6': 'rgba(112, 77, 0, 0.2590)',
    '7': 'rgba(110, 70, 0, 0.3570)',
    '8': 'rgba(109, 66, 0, 0.4870)',
    '9': 'rgba(83, 50, 0, 0.6040)',
    '10': 'rgba(71, 42, 0, 0.6400)',
    '11': 'rgba(54, 32, 0, 0.7060)',
    '12': 'rgba(19, 12, 0, 0.8320)',
  },
}

export const chartColors = {
  lo: {
    default: {
      '1': primitives.Tensor[4],
      '2': primitives.Tomato[4],
      '3': primitives.Plum[4],
      '4': primitives.Violet[4],
      '5': primitives.Cyan[4],
      '6': primitives.Grass[4],
      '7': primitives.Orange[4],
      '8': primitives.Brown[4],
      '9': primitives.Sky[4],
      '10': primitives.Mint[4],
      '11': primitives.Lime[4],
      '12': primitives.Amber[4],
      '13': primitives.Gray[4],
    },
    defaultHover: {
      '1': primitives.Tensor[5],
      '2': primitives.Tomato[5],
      '3': primitives.Plum[5],
      '4': primitives.Violet[5],
      '5': primitives.Cyan[5],
      '6': primitives.Grass[5],
      '7': primitives.Orange[5],
      '8': primitives.Brown[5],
      '9': primitives.Sky[5],
      '10': primitives.Mint[5],
      '11': primitives.Lime[5],
      '12': primitives.Amber[5],
      '13': primitives.Gray[5],
    },
    transparent: {
      '1': primitives.TensorAlpha[4],
      '2': primitives.TomatoAlpha[4],
      '3': primitives.PlumAlpha[4],
      '4': primitives.VioletAlpha[4],
      '5': primitives.CyanAlpha[4],
      '6': primitives.GrassAlpha[4],
      '7': primitives.OrangeAlpha[4],
      '8': primitives.BrownAlpha[4],
      '9': primitives.SkyAlpha[4],
      '10': primitives.MintAlpha[4],
      '11': primitives.LimeAlpha[4],
      '12': primitives.AmberAlpha[4],
      '13': primitives.GrayAlpha[4],
    },
    transparentHover: {
      '1': primitives.TensorAlpha[5],
      '2': primitives.TomatoAlpha[5],
      '3': primitives.PlumAlpha[5],
      '4': primitives.VioletAlpha[5],
      '5': primitives.CyanAlpha[5],
      '6': primitives.GrassAlpha[5],
      '7': primitives.OrangeAlpha[5],
      '8': primitives.BrownAlpha[5],
      '9': primitives.SkyAlpha[5],
      '10': primitives.MintAlpha[5],
      '11': primitives.LimeAlpha[5],
      '12': primitives.AmberAlpha[5],
      '13': primitives.GrayAlpha[5],
    },
  },
  hi: {
    default: {
      '1': primitives.Tensor[9],
      '2': primitives.Tomato[9],
      '3': primitives.Plum[9],
      '4': primitives.Violet[9],
      '5': primitives.Cyan[9],
      '6': primitives.Grass[9],
      '7': primitives.Orange[9],
      '8': primitives.Brown[9],
      '9': primitives.Sky[11],
      '10': primitives.Mint[11],
      '11': primitives.Lime[11],
      '12': primitives.Amber[11],
      '13': primitives.Gray[9],
    },
    defaultHover: {
      '1': primitives.Tensor[10],
      '2': primitives.Tomato[10],
      '3': primitives.Plum[10],
      '4': primitives.Violet[10],
      '5': primitives.Cyan[10],
      '6': primitives.Grass[10],
      '7': primitives.Orange[10],
      '8': primitives.Brown[10],
      '9': primitives.Sky[12],
      '10': primitives.Mint[12],
      '11': primitives.Lime[12],
      '12': primitives.Amber[12],
      '13': primitives.Gray[10],
    },
    transparent: {
      '1': primitives.TensorAlpha[9],
      '2': primitives.TomatoAlpha[9],
      '3': primitives.PlumAlpha[9],
      '4': primitives.VioletAlpha[9],
      '5': primitives.CyanAlpha[9],
      '6': primitives.GrassAlpha[9],
      '7': primitives.OrangeAlpha[9],
      '8': primitives.BrownAlpha[9],
      '9': primitives.SkyAlpha[9],
      '10': primitives.MintAlpha[9],
      '11': primitives.LimeAlpha[9],
      '12': primitives.AmberAlpha[9],
      '13': primitives.GrayAlpha[9],
    },
    transparenHover: {
      '1': primitives.TensorAlpha[10],
      '2': primitives.TomatoAlpha[10],
      '3': primitives.PlumAlpha[10],
      '4': primitives.VioletAlpha[10],
      '5': primitives.CyanAlpha[10],
      '6': primitives.GrassAlpha[10],
      '7': primitives.OrangeAlpha[10],
      '8': primitives.BrownAlpha[10],
      '9': primitives.SkyAlpha[10],
      '10': primitives.MintAlpha[10],
      '11': primitives.LimeAlpha[10],
      '12': primitives.AmberAlpha[10],
      '13': primitives.GrayAlpha[10],
    },
  },
  text: {
    bright: {
      hi: '#ffffff',
      lo: primitives.Gray[2],
    },
    dark: {
      hi: primitives.Gray[12],
      lo: primitives.Gray[11],
    },
  },
}
export const palette = {
  tensorBlue: primitives.Tensor[9],
  tensorTint1: primitives.Tensor[1],
  tensorTint1plus: primitives.Tensor[2],
  tensorTint2: primitives.Tensor[3],
  tensorTint3: primitives.Tensor[5],
  tensorTint4: primitives.Tensor[7],
  tensorTint5: primitives.Tensor[8],
  tensorShade1: primitives.Tensor[10],
  tensorShade2: primitives.Tensor[11],
  tensorShade3: primitives.Tensor[11],
  tensorShade4: primitives.Tensor[12],
  tensorShade5: primitives.Tensor[12],
  tensorShade6: primitives.Tensor[12],
  accentPurple: primitives.Purple[9],
  accentTint1: primitives.Purple[5],
  accentTint2: primitives.Purple[7],
  accentTint3: primitives.Purple[8],
  accentShade1: primitives.Purple[10],
  accentShade2: primitives.Purple[11],
  accentShade3: primitives.Purple[12],
  grey1: primitives.Gray[3],
  grey2: primitives.Gray[4],
  grey2plus: primitives.Gray[8],
  grey3: primitives.Gray[10],
  grey4: primitives.Gray[7],
  carbon: primitives.Gray[12],
  graphite: primitives.Gray[10],
  steel1: primitives.Gray[8],
  steel2: primitives.Gray[6],
  steel3: primitives.Gray[5],
  steel4: primitives.Gray[3],
  bright: '#ffffff',
  grayLight: primitives.Gray[6],
  divider: primitives.Gray[6],
  success: primitives.Green[9],
  successTint1: primitives.Green[4],
  successTint2: primitives.Green[6],
  successTint3: primitives.Green[7],
  successTint4: primitives.Green[8],
  successShade1: primitives.Green[10],
  successShade2: primitives.Green[11],
  successShade3: primitives.Green[12],
  caution: primitives.Yellow[9],
  cautionTint1: primitives.Yellow[3],
  cautionTint2: primitives.Yellow[6],
  cautionTint3: primitives.Yellow[7],
  cautionShade1: primitives.Yellow[8],
  cautionShade2: primitives.Yellow[11],
  cautionShade3: primitives.Yellow[12],
  error: primitives.Red[9],
  errorTint1: primitives.Red[4],
  errorTint2: primitives.Red[6],
  errorTint3: primitives.Red[7],
  errorTint4: primitives.Red[8],
  errorTint5: primitives.Red[9],
  errorShade1: primitives.Red[10],
  errorShade2: primitives.Red[11],
  errorShade3: primitives.Red[12],
  tooltipBg: primitives.Gray[9],
  transparent: 'rgba(255, 255, 255, 0.0000)',
  muted: primitives.Gray[2],
  info: primitives.Tensor[8],
  infoLight: primitives.Tensor[3],
  highlight: primitives.Tensor[3],
  black: primitives.Gray[12],
}

const aliases = {
  primary: palette.tensorBlue,
  primaryLight: palette.tensorTint5,
  primaryTint1: palette.tensorTint1,
  primaryTint1plus: palette.tensorTint1plus,
  primaryTint2: palette.tensorTint2,
  primaryTint3: palette.tensorTint3,
  primaryTint4: palette.tensorTint4,
  primaryTint5: palette.tensorTint5,
  primaryShade1: palette.tensorShade1,
  primaryShade2: palette.tensorShade2,
  primaryShade3: palette.tensorShade3,
  primaryShade4: palette.tensorShade4,
  primaryShade5: palette.tensorShade5,
  primaryShade6: palette.tensorShade6,
  accent: palette.accentPurple,
  danger: palette.error,
  dangerTint1: palette.errorTint1,
  dangerTint2: palette.errorTint2,
  dangerTint3: palette.errorTint3,
  dangerTint4: palette.errorTint4,
  dangerTint5: palette.errorTint5,
  dangerShade1: palette.errorShade1,
  dangerShade2: palette.errorShade2,
  dangerShade3: palette.errorShade3,
  warning: palette.caution,
  warningShade3: palette.cautionShade3,
  warningShade2: palette.cautionShade2,
  warningShade1: palette.cautionShade1,
  warningTint3: palette.cautionTint3,
  warningTint2: palette.cautionTint2,
  warningTint1: palette.cautionTint1,
  text: palette.carbon,
  textGray: palette.graphite,
  gray: palette.grey1,
  gray1: palette.grey1,
  gray2: palette.grey2,
  gray2plus: palette.grey2plus,
  gray3: palette.grey3,
  gray4: palette.grey4,
  background: palette.tensorTint1,
  white: palette.bright,
}
export const colors = {
  ...palette,
  ...aliases,
}
