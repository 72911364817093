import { ApolloError } from '@apollo/client'
import { log } from 'logger'
import Trans from 'next-translate/Trans'
import { toast } from 'toast'
import { GENERIC_SERVER_ERROR_KEY, RESOURCE_ALREADY_EXISTS_KEY } from './message-keys'

type handleCrudErrorOptions = {
  toastContainerId?: string
}
export const handleCrudError = async (error: any, options?: handleCrudErrorOptions) => {
  log.debug('CRUD ERROR: ', error)
  if (!(error instanceof ApolloError)) log.error(error)
  if ((error as any).message === 'There is already a resource with that name') {
    toast.error(<Trans i18nKey={RESOURCE_ALREADY_EXISTS_KEY} />, {
      containerId: options?.toastContainerId ?? 'default',
    })
  } else {
    toast.error(<Trans i18nKey={GENERIC_SERVER_ERROR_KEY} />, {
      containerId: options?.toastContainerId ?? 'default',
    })
  }
}
