import { FC } from 'react'
import { FillIcon } from './common/FillIcon'
import { SvgIconProps } from './types'

export const ErrorIcon: FC<SvgIconProps> = ({ ...props }) => (
  <FillIcon
    {...props}
    data-testid="error-icon"
    d={[
      'M8.00002 1C4.13402 1 1 4.134 1 7.99997C1 11.8659 4.13402 15 8.00002 15C11.866 15 15 11.8659 15 7.99997C15 4.134 11.866 1 8.00002 1ZM2.0041 7.99997C2.0041 4.68855 4.68857 2.0041 8.00002 2.0041C11.3114 2.0041 13.9959 4.68855 13.9959 7.99997C13.9959 11.3114 11.3114 13.9959 8.00002 13.9959C4.68857 13.9959 2.0041 11.3114 2.0041 7.99997Z',
      'M7.12504 10.625C7.12504 10.1418 7.51679 9.75 8.00004 9.75C8.4833 9.75 8.87505 10.1418 8.87505 10.625C8.87505 11.1082 8.4833 11.5 8.00004 11.5C7.51679 11.5 7.12504 11.1082 7.12504 10.625Z',
      'M7.12504 5.375C7.12504 4.89175 7.51679 4.5 8.00004 4.5C8.4833 4.5 8.87505 4.89175 8.87505 5.375V8C8.87505 8.48325 8.4833 8.875 8.00004 8.875C7.51679 8.875 7.12504 8.48325 7.12504 8V5.375Z',
    ]}
  />
)
