export const scrollbars = {
  '::-webkit-scrollbar': {
    width: '6px',
  },
  '::-webkit-scrollbar-track': {
    bg: 'steel4',
  },
  '::-webkit-scrollbar-thumb': {
    bg: 'steel2',
  },
}

export const roundedScrollbars = {
  '::-webkit-scrollbar': {
    width: '6px',
  },
  '::-webkit-scrollbar-track': {
    bg: 'steel4',
    borderRadius: '5px',
  },
  '::-webkit-scrollbar-thumb': {
    bg: 'steel2',
    borderRadius: '5px',
  },
}
