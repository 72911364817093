// Japanese fiscal year starts on 1st of April, therefore if
// today is 2nd of February 2022, fiscal year is still 2021

export const getCurrentFiscalYear = () => {
  const thisYear = new Date().getUTCFullYear()
  const newYearDate = new Date(
    new Date(new Date().setUTCHours(9)).setUTCFullYear(thisYear, 0, 0)
  ).getTime()
  const newJapaneseFiscalYearDate = new Date(
    new Date(new Date().setUTCHours(9)).setUTCFullYear(thisYear, 3, 1)
  ).getTime()
  const currentDate = Date.now()

  if (currentDate < newJapaneseFiscalYearDate && currentDate > newYearDate) {
    return thisYear - 1
  }
  return thisYear
}
