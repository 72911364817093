/* eslint-disable  */
import { StyleObject } from 'typ'
import { srOnly } from './utils'
import { websiteText } from './website-text'

export const websiteStyles: Record<string, StyleObject> = {
  root: {
    fontFamily: 'body',
    lineHeight: 'body',
    fontWeight: 'body',
  },
  h1: websiteText.h1,
  h2: websiteText.h2,
  h3: websiteText.h3,
  h4: websiteText.h4,
  h5: websiteText.h5,
  h6: websiteText.h6,
  p: websiteText.paragraph1,
  hr: {
    color: 'divider',
  },
  a: {
    fontSize: 'inherit',
    fontFamily: 'inherit',
    textDecoration: 'none',
    cursor: 'pointer',
    borderRadius: 2,
    outline: 'none',
    '&:link': { color: 'primary' },
    '&:visited': { color: 'primary' },
    '&:hover': { textDecoration: 'underline' },
    '&:active': { textDecoration: 'underline' },
    '&:focus-visible': { boxShadow: 'focusRing' },
  },
  srOnly,
  link: {
    fontSize: 2,
    color: 'textGray',
    width: 'max-content',
    transition: 'color .2s ease',
    ':link': { color: 'textGray' },
    ':link:hover': {
      textDecoration: 'none',
      color: 'text',
    },
    ':visited': { color: 'textGray' },
    ':visited:hover': {
      textDecoration: 'none',
      color: 'text',
    },
    ':focus-visible': {
      outline: 'none',
      borderRadius: '2',
      boxShadow: 'focusRing',
    },
  },
}
