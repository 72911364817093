import { captureMessage as emitSentryMessage } from '@sentry/nextjs'

export const logSentryError = (message: string) => {
  if (
    typeof window !== 'undefined' &&
    process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT === 'production'
  ) {
    emitSentryMessage(message, 'error')
  }
}
