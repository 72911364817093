import { StoreSlice } from '../types'

type BaselineViewTimeframe = 'monthly' | 'yearly'

export interface BaselineViewSlice {
  baselineViewTimeframe: BaselineViewTimeframe
  baselineViewSetTimeframe: (timeframe: BaselineViewTimeframe) => void
}
export const createBaselineViewSlice: StoreSlice<BaselineViewSlice> = (set, _get) => ({
  baselineViewTimeframe: 'monthly',
  baselineViewSetTimeframe: (timeframe: BaselineViewTimeframe) =>
    set(() => ({
      baselineViewTimeframe: timeframe,
    })),
})
