import { gradients, gradientStarts } from './gradients'

const baseGradientSyntax = {
  backgroundClip: 'text',
  textFillColor: 'transparent',
  backgroundSize: '100%',
  maxWidth: 'max-content',
}
export const textGradients = {
  blue: {
    ...baseGradientSyntax,
    backgroundColor: gradientStarts.blue0,
    backgroundImage: gradients.blue0,
  },
  green: {
    ...baseGradientSyntax,
    backgroundColor: gradientStarts.green0,
    backgroundImage: gradients.green0,
  },
  red: {
    ...baseGradientSyntax,
    backgroundColor: gradientStarts.red0,
    backgroundImage: gradients.red0,
  },
  gray: {
    ...baseGradientSyntax,
    backgroundColor: gradientStarts.gray0,
    backgroundImage: gradients.gray0,
  },
}
