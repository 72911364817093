export const zIndices = {
  hidden: -1,
  base: 100,
  'loading-overlay': 1001,
  nav: 1002,
  sidenav: 1005,
  'modal-overlay-0': 1020,
  'modal-0': 1030,
  'modal-overlay-1': 1031,
  'modal-1': 1032,
  'popup-overlay': 1040,
  popup: 1050,
  tooltip: 1060,
  top: 1100,
}
