import { useEffect, useRef } from 'react'
import { useEventCallback } from './useEventCallback'

export const useWindowVisibilityObserver = (onChange?: (visible: boolean) => void) => {
  const isVisibleRef = useRef<boolean>(true)

  const toggleVisibility = useEventCallback((visible: boolean) => {
    if (isVisibleRef.current !== visible) {
      isVisibleRef.current = visible
      onChange?.(visible)
    }
  })

  useEffect(() => {
    const handler = () => {
      toggleVisibility(!document.hidden)
    }
    document.addEventListener('visibilitychange', handler)
    return () => {
      document.removeEventListener('visibilitychange', handler)
    }
  }, [toggleVisibility])

  return isVisibleRef
}
