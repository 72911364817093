/* eslint-disable */
import { ascending } from './ascending'
import { descending } from './descending'
import { Comparator, Accessor } from './types'

export interface Bisector<T, U> {
  left(array: ArrayLike<T>, x: U, lo?: number, hi?: number): number
  right(array: ArrayLike<T>, x: U, lo?: number, hi?: number): number
  center(array: ArrayLike<T>, x: U, lo?: number, hi?: number): number
}

function isAccessor<T, U>(fn: Function): fn is Accessor<T, U> {
  return fn.length === 1
}

function zero() {
  return 0
}

export function bisector<T, U>(
  f: Comparator<T, U> | Accessor<T, U>
): Bisector<T, U> {
  let compare1: Comparator<T, U>,
    compare2: Comparator<T, U>,
    delta: Comparator<T, U>

  // If an accessor is specified, promote it to a comparator. In this case we
  // can test whether the search value is (self-) comparable. We can’t do this
  // for a comparator (except for specific, known comparators) because we can’t
  // tell if the comparator is symmetric, and an asymmetric comparator can’t be
  // used to test whether a single value is comparable.
  if (isAccessor<T, U>(f)) {
    compare1 = ascending
    compare2 = (d, x) => ascending(f(d), x)
    delta = (d, x) => Number(f(d)) - Number(x)
  } else {
    compare1 = f === ascending || f === descending ? f : zero
    compare2 = f
    delta = f
  }

  function left(a: ArrayLike<T>, x: U, lo = 0, hi = a.length) {
    if (lo < hi) {
      if (compare1(x as any, x as any) !== 0) return hi
      do {
        const mid = (lo + hi) >>> 1
        if (compare2(a[mid], x) < 0) lo = mid + 1
        else hi = mid
      } while (lo < hi)
    }
    return lo
  }

  function right(a: ArrayLike<T>, x: U, lo = 0, hi = a.length) {
    if (lo < hi) {
      if (compare1(x as any, x as any) !== 0) return hi
      do {
        const mid = (lo + hi) >>> 1
        if (compare2(a[mid], x) <= 0) lo = mid + 1
        else hi = mid
      } while (lo < hi)
    }
    return lo
  }

  function center(a: ArrayLike<T>, x: U, lo = 0, hi = a.length) {
    const i = left(a, x, lo, hi - 1)
    return i > lo && delta(a[i - 1], x) > -delta(a[i], x) ? i - 1 : i
  }

  return { left, center, right }
}
