import { makeVar } from '@apollo/client'
import { useHandleGenericServerError } from 'common-v2/error-helpers/useHandleGenericServerError'
import {
  SimulationStatus,
  useTriggerSolarAssetSimulationMutation,
} from 'generated/tensor-api/generated-types'
import { useCallback } from 'react'
import store from 'store'

export const localSimulationStatusVar = makeVar<
  Record<string, { status: SimulationStatus; date: Date }>
>({})

export const useTriggerSimulation = () => {
  const handleGenericServerError = useHandleGenericServerError()
  const workspaceId = store.useGetCurrentWorkspaceId()()

  const [triggerSimulation, mutationState] = useTriggerSolarAssetSimulationMutation({
    onCompleted: ({
      solarAsset: {
        triggerSimulation: { errors },
      },
    }) => {
      if (errors?.length) {
        handleGenericServerError('trigger-simulation-error')
      }
    },
    onError: () => handleGenericServerError('trigger-simulation-error'),
  })

  const handleRerunSimulation = useCallback(
    async (id: string) => {
      const { data } = await triggerSimulation({
        variables: { input: { id, workspaceId } },
      })
      if ((data?.solarAsset.triggerSimulation.errors?.length ?? 0) <= 0) {
        localSimulationStatusVar({
          ...localSimulationStatusVar(),
          [id]: { status: SimulationStatus.Running, date: new Date() },
        })
      }
    },
    [triggerSimulation, workspaceId]
  )

  return [handleRerunSimulation, mutationState] as const
}
