import { v4 } from 'uuid'
import { StoreSlice } from '../types'

export interface CreateSolarAssetSlice {
  // TODO: SETUP TYPES IN A SHARED FOLDER INSTEAD OF USING ANY
  createSolarAssetInitialValues: any
  createSolarAssetNonce: string | null
  createSolarAssetChangeInitialValues: (initialValues: any) => string
  createSolarAssetGetInitialValues: (nonce: string) => any
}

export const createCreateSolarAssetSlice: StoreSlice<CreateSolarAssetSlice> = (set, get) => ({
  createSolarAssetInitialValues: {},
  //The nonce is a value that verifies that only the time the user actually clicks on the button and redirects to the form, the initial values are pre-filled
  //Subsequent visits to the new asset form will not have the nonce in the url and not have the initial values pre-filled
  createSolarAssetNonce: null,
  createSolarAssetChangeInitialValues: (initialValues) => {
    const nonce = v4().slice(0, 8)
    set(() => ({
      createSolarAssetInitialValues: initialValues,
      createSolarAssetNonce: nonce,
    }))
    return nonce
  },
  createSolarAssetGetInitialValues: (nonce) => {
    if (nonce === get().createSolarAssetNonce) {
      return get().createSolarAssetInitialValues
    }
    return {}
  },
})
