import { StoreSlice } from '../types'

export interface AdminNavigationSlice {
  workspaces: string
  users: string
  simulatedSites: string
  setWorkspaces: (path: string) => void
  setUsers: (path: string) => void
  setSimulatedSites: (path: string) => void
}

export const createAdminNavigationSlice: StoreSlice<AdminNavigationSlice> = (set, _get) => ({
  workspaces: '/',
  users: '/',
  simulatedSites: '/',
  setWorkspaces: (path: string) => set({ workspaces: path }),
  setUsers: (path: string) => set({ users: path }),
  setSimulatedSites: (path: string) => set({ simulatedSites: path }),
})
