export const ABS_TIMEFRAMES = [
  'custom',
  'threeDays',
  'daily',
  'weekly',
  'biweekly',
  'monthly',
  'quarterly',
  'yearly',
] as const

export const REL_TIMEFRAMES = ['mtd', 'qtd', 'ytd', 'all'] as const

export type AbsTimeframe = typeof ABS_TIMEFRAMES[number]
export type RelTimeframe = typeof REL_TIMEFRAMES[number]
export type IntervalSelectorTimeframe = AbsTimeframe | RelTimeframe
