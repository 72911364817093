type SortOrder = 'asc' | 'desc'
type Options = { order: SortOrder }

export function gojuonSort<T extends string | number | Record<string, any>>(
  arr: T[],
  options?: Options | null,
  accessor: (el: T) => string = (el) => el as unknown as string
) {
  const order = options?.order ?? 'asc'
  return Array.from(arr).sort((a, b) => {
    const aVal = accessor(a) ? String(accessor(a)) : ''
    const bVal = accessor(b) ? String(accessor(b)) : ''
    const result = aVal.localeCompare(bVal, 'jp', { numeric: true })
    return order === 'asc' ? result : -result
  })
}
