const fallbackFonts =
  'system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", sans-serif'

export const fonts = {
  body: `IBM Plex Sans JP, ${fallbackFonts}`,
  heading: `IBM Plex Sans JP, ${fallbackFonts}`,
  monospace: `IBM Plex Sans JP, ${fallbackFonts}`,
}

export const fontWeights = {
  body: 400,
  heading: 400,
  medium: 500,
  'semi-bold': 600,
  bold: 700,
}

export const fontSizes = [10, 12, 14, 16, 20, 24, 34, 48, 60, 96]

export const lineHeights = {
  body: 1.5,
}
