import { FC } from 'react'
import { FillIcon } from './common/FillIcon'
import { SvgIconProps } from './types'

export const CheckCircleIcon: FC<SvgIconProps> = ({ ...props }) => (
  <FillIcon
    {...props}
    data-testid="check-circle-icon"
    d={[
      'M8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14ZM8 15C11.866 15 15 11.866 15 8C15 4.13401 11.866 1 8 1C4.13401 1 1 4.13401 1 8C1 11.866 4.13401 15 8 15Z',
      'M11.3322 5.6263C11.5386 5.80975 11.5572 6.12579 11.3737 6.33218L7.61 10.5663C7.28065 10.9369 6.69706 10.9217 6.38738 10.5346L4.60957 8.31235C4.43706 8.09672 4.47202 7.78207 4.68765 7.60957C4.90328 7.43706 5.21793 7.47202 5.39043 7.68765L7.02012 9.72476L10.6263 5.66782C10.8098 5.46143 11.1258 5.44284 11.3322 5.6263Z',
    ]}
  />
)
