import { StyleObject } from 'typ'
import { ellipsize } from './utils'

export type TextStyleVariant =
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'h5'
  | 'h6'
  | 'paragraph1'
  | 'paragraph2'
  | 'secondary1'
  | 'secondary2'
  | 'secondary3'
  | 'body1'
  | 'body2'
  | 'body3'
  | 'title1'
  | 'title2'
  | 'title3'
  | 'helper1'
  | 'helper2'
  | 'capsHeader'
  | 'disabled'
export type TextUtilityVariant = 'ellipsize'

const baseHeadingStyles: StyleObject = {
  color: 'text',
  fontWeight: 'medium',
  margin: 0,
}
const baseBodyStyles: StyleObject = {
  fontFamily: 'body',
  color: 'text',
  lineHeight: 'body',
}

export const text: Record<TextStyleVariant | TextUtilityVariant, StyleObject> =
  {
    ellipsize,
    h1: {
      ...baseHeadingStyles,
      fontSize: 9,
      lineHeight: '110%',
    },
    h2: {
      ...baseHeadingStyles,
      fontSize: 8,
      lineHeight: '120%',
    },
    h3: {
      ...baseHeadingStyles,
      lineHeight: '125%',
      fontSize: 7,
    },
    h4: {
      ...baseHeadingStyles,
      lineHeight: '130%',
      fontSize: 6,
    },
    h5: {
      ...baseHeadingStyles,
      fontSize: 5,
      lineHeight: '140%',
    },
    h6: {
      ...baseHeadingStyles,
      fontSize: 4,
      lineHeight: '140%',
    },
    secondary1: {
      ...baseBodyStyles,
      fontSize: 3,
      color: 'textGray',
    },
    secondary2: {
      ...baseBodyStyles,
      fontSize: 2,
      color: 'textGray',
    },
    secondary3: {},
    body1: {
      ...baseBodyStyles,
      fontSize: 3,
    },
    body2: {
      ...baseBodyStyles,
      fontSize: 2,
    },
    body3: {},
    paragraph1: {
      ...baseBodyStyles,
      mb: 2,
      fontSize: 3,
    },
    paragraph2: {
      ...baseBodyStyles,
      fontSize: 2,
      mb: 2,
    },
    title1: {
      ...baseBodyStyles,
      fontWeight: 'medium',
      fontSize: 3,
    },
    title2: {
      ...baseBodyStyles,
      fontSize: 2,
      fontWeight: 'medium',
    },
    title3: {},
    helper1: {
      ...baseBodyStyles,
      fontSize: 1,
    },
    helper2: {
      ...baseBodyStyles,
      fontSize: 0,
    },
    capsHeader: {
      fontFamily: 'body',
      textTransform: 'uppercase',
      fontSize: 1,
      color: 'primaryTint5',
      fontWeight: 'medium',
      lineHeight: '1.125rem',
    },
    disabled: {
      fontFamily: 'body',
      color: 'gray3',
      cursor: 'not-allowed',
    },
  }

export const textJa: Record<
  TextStyleVariant | TextUtilityVariant,
  StyleObject
> = {
  ...text,
  h1: {
    ...text.h1,
    lineHeight: '120%',
  },
  h2: {
    ...text.h2,
    lineHeight: '125%',
  },
  h3: {
    ...text.h3,
    lineHeight: '130%',
  },
  h4: {
    ...text.h4,
    lineHeight: '135%',
  },
  h5: {
    ...text.h5,
    lineHeight: '140%',
  },
  h6: {
    ...text.h6,
    lineHeight: '140%',
  },
}
