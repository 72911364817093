import { createSelectorHooks } from 'auto-zustand-selectors-hook'
import { create } from 'zustand'
import { persist } from 'zustand/middleware'
import { tensorAdminMigrations } from './migrations'
import {
  TensorAdminPersistedSlice,
  TensorAdminSessionSlice,
  createPersistedStoreSlice,
  createSessionStoreSlice,
} from './root-slices'

// STORE BASES
const adminStoreBase = create(
  persist(createPersistedStoreSlice, {
    name: 'tensor-cloud-admin-storage',
    version: 1,
    migrate: (persistedState, version) => {
      let state = persistedState
      for (let i = version; i < tensorAdminMigrations.length; i++) {
        state = tensorAdminMigrations[i](state)
      }
      return state as TensorAdminPersistedSlice
    },
  })
)
const adminSessionStoreBase = create(createSessionStoreSlice)

// STORE HOOKS
export const useAdminStore = createSelectorHooks<TensorAdminPersistedSlice>(adminStoreBase)
export const useAdminSessionStore =
  createSelectorHooks<TensorAdminSessionSlice>(adminSessionStoreBase)
