import { colors } from './colors'

export const shadows = {
  xs: '0 1px 1px rgb(0 0 0 / 0.05)',
  sm: '0 1px 2px rgb(0 0 0 / 0.1)',
  md: '0 4px 3px rgb(0 0 0 / 0.07)',
  lg: '5px 10px 25px rgb(64 90 163 / .1)',
  'chart-tooltip': '5px 10px 25px rgb(64 90 163 / .05)',
  xl: '0 20px 13px rgb(0 0 0 / 0.03)',
  menu: '0px 4px 8px 0px rgba(44, 39, 56, 0.08)',
  focusRing: `0 0 0 2px ${colors.primaryTint4}`,
} as any
// TS asks for box shadows array, but works with obj
