import { toggleSelection } from 'array'
import {
  StoreSlice,
  SolarAssetTab,
  CashFlowAggregation,
  MYResolution,
  OpexKey,
  RevenuesAggregation,
  CashFlowBreakdownUnit,
} from '../types'
import { SOLAR_ASSET_TABS, OPEX_KEYS } from '../constants'

export interface AssetViewSlice {
  solarAssetActiveTab: SolarAssetTab
  setSolarAssetActiveTab: (tab: SolarAssetTab) => void
  generationResolution: MYResolution
  setGenerationResolution: (res: MYResolution) => void
  isCapexGrouped: boolean
  setIsCapexGrouped: () => void
  opexResolution: MYResolution
  setOpexResolution: (res: MYResolution) => void
  opexCostTypes: OpexKey[]
  toggleOpexCostTypes: (val: OpexKey) => void
  revenuesResolution: MYResolution
  setRevenuesResolution: (res: MYResolution) => void
  revenuesAggregation: RevenuesAggregation
  setRevenuesAggregation: (val: RevenuesAggregation) => void
  cashFlowResolution: MYResolution
  setCashFlowResolution: (res: MYResolution) => void
  cashFlowAggregation: CashFlowAggregation
  setCashFlowAggregation: (val: CashFlowAggregation) => void
  cashFlowBreakdownUnit: CashFlowBreakdownUnit
  setCashFlowBreakdownUnit: (val: CashFlowBreakdownUnit) => void
}

export const createAssetViewSlice: StoreSlice<AssetViewSlice> = (
  set,
  _get
) => ({
  solarAssetActiveTab: SOLAR_ASSET_TABS[0],
  setSolarAssetActiveTab: (tab: SolarAssetTab) =>
    set(() => ({ solarAssetActiveTab: tab })),
  generationResolution: 'yearly',
  setGenerationResolution: (res: MYResolution) =>
    set(() => ({ generationResolution: res })),

  isCapexGrouped: false,
  setIsCapexGrouped: () =>
    set((state) => ({ isCapexGrouped: !state.isCapexGrouped })),

  opexResolution: 'yearly',
  setOpexResolution: (res: MYResolution) =>
    set(() => ({ opexResolution: res })),

  opexCostTypes: Array.from(OPEX_KEYS),
  toggleOpexCostTypes: (val: OpexKey) =>
    set((state) => ({
      opexCostTypes: toggleSelection(state.opexCostTypes, val),
    })),

  revenuesResolution: 'yearly',
  setRevenuesResolution: (res: MYResolution) =>
    set(() => ({ revenuesResolution: res })),

  revenuesAggregation: 'total',
  setRevenuesAggregation: (val: RevenuesAggregation) =>
    set(() => ({ revenuesAggregation: val })),

  cashFlowResolution: 'yearly',
  setCashFlowResolution: (res: MYResolution) =>
    set(() => ({ cashFlowResolution: res })),

  cashFlowAggregation: 'individual',
  setCashFlowAggregation: (val: CashFlowAggregation) =>
    set(() => ({ cashFlowAggregation: val })),
  cashFlowBreakdownUnit: 'percentage',
  setCashFlowBreakdownUnit: (val: CashFlowBreakdownUnit) =>
    set(() => ({ cashFlowBreakdownUnit: val })),
})
