import { ANALYTICS_MARKETS_TABS, ANALYTICS_SUPPLY_DEMAND_TABS } from '../constants'
import { AnalyticsMarketsTab, AnalyticsSupplyDemandTab, StoreSlice } from '../types'

export interface AnalyticsPermanentSlice {
  // TABS
  analyticsMarketsActiveTab: AnalyticsMarketsTab
  setAnalyticsMarketsActiveTab: (tab: AnalyticsMarketsTab) => void
  analyticsSupplyDemandActiveTab: AnalyticsSupplyDemandTab
  setAnalyticsSupplyDemandActiveTab: (tab: AnalyticsSupplyDemandTab) => void
}
export const createAnalyticsPermanentSlice: StoreSlice<AnalyticsPermanentSlice> = (set, _get) => ({
  // TABS
  analyticsMarketsActiveTab: ANALYTICS_MARKETS_TABS[0],
  setAnalyticsMarketsActiveTab: (tab: AnalyticsMarketsTab) =>
    set(() => ({ analyticsMarketsActiveTab: tab })),
  analyticsSupplyDemandActiveTab: ANALYTICS_SUPPLY_DEMAND_TABS[0],
  setAnalyticsSupplyDemandActiveTab: (tab: AnalyticsSupplyDemandTab) =>
    set(() => ({ analyticsSupplyDemandActiveTab: tab })),
})
