import { SortOrder } from './types'

type Numeric = number | string
type Options = { order?: SortOrder }

export function sortNumeric<T extends Numeric | Record<string, any>>(
  arr: T[],
  options?: Options | null,
  accessor: (el: T) => Numeric = (el) => el as unknown as Numeric
): T[] {
  const order = options?.order ?? 'asc'

  return [...arr].sort((a, b) => {
    const valA = Number(accessor(a) ?? 0)
    const valB = Number(accessor(b) ?? 0)
    if (Number.isNaN(valA) || Number.isNaN(valB)) return 0
    return order === 'asc' ? valA - valB : valB - valA
  })
}
