import { StoreSlice } from '../types'

type AuthenticationUser = { id?: string | null; username?: string; isConfirmed?: boolean }

export interface AuthenticationSlice {
  user: AuthenticationUser | null
  setUser: (user: AuthenticationUser) => void
}

export const createAuthenticationSlice: StoreSlice<AuthenticationSlice> = (set, _get) => ({
  user: null,
  setUser: (user: AuthenticationUser) => set({ user }),
})
