import { StoreSlice } from '../types'

type ChainFlow = 'scenarios-chain-edit' | 'solar-assets-chain-edit'
type ChainActionsState = {
  activeChainFlowItemId: string | null
  activeChainFlowItemIndex: number
  chainFlowId: ChainFlow | null
  chainFlowItemIds: string[]
  chainFlowItemsCount: number
  isFirstChainFlowItem: boolean
  isLastChainFlowItem: boolean
}

const initialState = {
  activeChainFlowItemId: null,
  activeChainFlowItemIndex: 0,
  chainFlowId: null,
  chainFlowItemIds: [],
  chainFlowItemsCount: 0,
  isFirstChainFlowItem: true,
  isLastChainFlowItem: false,
}

export type ChainActionsSlice = ChainActionsState & {
  setChainFlowId: (id: ChainFlow) => void
  setChainFlowItemIds: (ids: string[]) => void
  setActiveChainFlowItemId: (id: string) => void
  initializeChainFlow: (id: ChainFlow, ids: string[]) => void
  goToNextChainFlowItem: () => void
  goToPreviousChainFlowItem: () => void
  resetChainFlow: () => void
}

export const createChainActionsSlice: StoreSlice<ChainActionsSlice> = (set, get) => ({
  ...initialState,
  setChainFlowId: (id) => set({ chainFlowId: id }),
  setChainFlowItemIds: (ids) => set({ chainFlowItemIds: ids }),
  setActiveChainFlowItemId: (id) => {
    const { chainFlowItemIds } = get()
    const targetIndex = chainFlowItemIds.indexOf(id)
    set({
      activeChainFlowItemId: id,
      activeChainFlowItemIndex: targetIndex,
      isLastChainFlowItem: targetIndex === chainFlowItemIds.length - 1,
      isFirstChainFlowItem: targetIndex === 0,
    })
  },
  initializeChainFlow: (flowId, itemIds) =>
    set({
      chainFlowId: flowId,
      chainFlowItemIds: itemIds,
      activeChainFlowItemId: itemIds[0],
      activeChainFlowItemIndex: 0,
      isFirstChainFlowItem: true,
      isLastChainFlowItem: itemIds.length === 1,
      chainFlowItemsCount: itemIds.length,
    }),
  goToNextChainFlowItem: () => {
    const { activeChainFlowItemIndex, chainFlowItemIds } = get()
    const nextIndex = activeChainFlowItemIndex + 1
    if (nextIndex >= chainFlowItemIds.length) return
    set({
      activeChainFlowItemId: chainFlowItemIds[nextIndex],
      activeChainFlowItemIndex: nextIndex,
      isFirstChainFlowItem: false,
      isLastChainFlowItem: nextIndex === chainFlowItemIds.length - 1,
    })
  },
  goToPreviousChainFlowItem: () => {
    const { activeChainFlowItemIndex, chainFlowItemIds } = get()
    const previousIndex = activeChainFlowItemIndex - 1
    if (previousIndex < 0) return
    set({
      activeChainFlowItemId: chainFlowItemIds[previousIndex],
      activeChainFlowItemIndex: previousIndex,
      isFirstChainFlowItem: previousIndex === 0,
      isLastChainFlowItem: false,
    })
  },
  resetChainFlow: () => set(initialState),
})
