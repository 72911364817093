import { Maybe } from 'typ'
/**
 *
 * @param name A name to add a saxon genitive
 * @returns The name including the correct for of a saxon genitive
 */

export const addSaxonGenitive = (
  name: Maybe<string>,
  lang: Maybe<string>
): string => {
  if (!name) return ''
  if (!lang || lang !== 'en') return name
  const apostrophe = String.fromCharCode(39)
  return name.toLowerCase().charAt(name.length - 1) === 's'
    ? `${name}${apostrophe}`
    : `${name}${apostrophe}s`
}
