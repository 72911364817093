/* eslint-disable  */
import { useThemeUI } from 'theme-ui'
import {
  COLLAPSED_SIDE_NAV_WIDTH,
  SIDE_NAV_WIDTH,
  TOP_NAV_HEIGHT,
  TextStyleVariant,
  TextUtilityVariant,
  WEBSITE_MOBILE_TOP_NAV_HEIGHT,
  WEBSITE_TOP_NAV_HEIGHT,
  badges,
  buttons,
  chartColors,
  colors,
  cssValueToNumber,
  fonts,
  forms,
  gradientStarts,
  gradientStops,
  gradients,
  layout,
  links,
  noSelect,
  roundedScrollbars,
  scrollbars,
  shadows,
  space,
  stableOverflowRules,
  styles,
  text,
  textGradients,
} from './assets'
import { theme as cloudTheme, makeLocalizedTheme } from './tensor-cloud-theme'
import { makeLocalizedWebsiteTheme, websiteTheme } from './website-theme'

export type ExactTheme = typeof cloudTheme

export const useTheme = () => {
  const context = useThemeUI()
  return context.theme as ExactTheme
}

export {
  COLLAPSED_SIDE_NAV_WIDTH,
  SIDE_NAV_WIDTH,
  TOP_NAV_HEIGHT,
  WEBSITE_MOBILE_TOP_NAV_HEIGHT,
  WEBSITE_TOP_NAV_HEIGHT,
  badges,
  buttons,
  chartColors,
  colors,
  cssValueToNumber,
  fonts,
  forms,
  gradientStarts,
  gradientStops,
  gradients,
  layout,
  links,
  makeLocalizedTheme,
  makeLocalizedWebsiteTheme,
  noSelect,
  roundedScrollbars,
  scrollbars,
  shadows,
  space,
  stableOverflowRules,
  styles,
  text,
  textGradients,
  websiteTheme,
}

export type { TextStyleVariant, TextUtilityVariant }

export default cloudTheme
