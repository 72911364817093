import { CSSObject } from 'typ'

export const srOnly: CSSObject = {
  position: 'absolute',
  left: '-10000000px',
  width: '1px',
  height: '1px',
  padding: 0,
  margin: '-1px',
  overflow: 'hidden',
  clip: 'rect(0, 0, 0, 0)',
  whiteSpace: 'nowrap',
  borderWidth: 0,
}

export const ellipsize: CSSObject = {
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
}

export const noSelect: CSSObject = {
  webkitTouchCallout: 'none' /* iOS Safari */,
  webkitUserSelect: 'none' /* Safari */,
  khtmlUserSelect: 'none' /* Konqueror HTML */,
  mozUserSelect: 'none' /* Old versions of Firefox */,
  msUserSelect: 'none' /* Internet Explorer/Edge */,
  userSelect:
    'none' /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */,
}

export const cssValueToNumber = (size: string): number => {
  const emPxValue = 16

  const convertedSize = size
    .replace(
      /(\d*\.?\d+)\s*em/g,
      (_, value) => `${parseFloat(value) * emPxValue}px`
    )
    .replace(
      /(\d*\.?\d+)\s*rem/g,
      (_, value) => `${parseFloat(value) * emPxValue}px`
    )
    .replace(/(\d*\.?\d+)\s*px/g, (_, value) => `${parseFloat(value)}px`)

  const matches = convertedSize.match(/(\d*\.?\d+)\s*px/g)

  if (matches && matches.length > 0) {
    return parseFloat(matches[0])
  }
  throw new Error('Invalid CSS size measurement')
}
