import type { Id, ToastContent, ToastOptions } from 'react-toastify'
import { toast as toastify } from 'react-toastify'

const defaultOptions: ToastOptions = {
  position: 'top-right',
  autoClose: 5000,
  containerId: 'default',
}
interface RemoveParams {
  id?: Id
  containerId: Id
}

export const toast = {
  ...toastify,
  success: (content: ToastContent, options: ToastOptions = {}) =>
    toastify.success(content, { ...defaultOptions, ...options }),
  info: (content: ToastContent, options: ToastOptions = {}) =>
    toastify.info(content, { ...defaultOptions, ...options }),
  warn: (content: ToastContent, options: ToastOptions = {}) =>
    toastify.warn(content, { ...defaultOptions, ...options }),
  error: (content: ToastContent, options: ToastOptions = {}) => {
    toastify.error(content, { ...defaultOptions, ...options })
  },
  dismiss: (params: RemoveParams | Id) => {
    function isRemoveParams(args: RemoveParams | Id): args is RemoveParams {
      return (args as RemoveParams).containerId !== undefined
    }
    if (isRemoveParams(params)) {
      toastify.dismiss({ id: params.id, containerId: params.containerId })
    } else {
      toastify.dismiss(params)
    }
  },
}
