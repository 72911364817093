import { addDays } from 'date-fns'
import { getCurrentJpDayStart } from 'datetime'
import { StoreSlice } from '../types'

export interface EditAssetPlanSlice {
  editAssetPlanStepIndex: number
  editAssetPlanIsConsentAgreed: boolean
  editAssetPlanBatteryChargeData: number[]
  editAssetPlanDemandData: number[]
  editAssetPlanDate: Date
  setEditAssetPlanDate: (date: Date) => void
  toggleEditAssetPlanConsentAgreement: () => void
  toEditAssetPlanNextStep: () => void
  toEditAssetPlanPrevStep: () => void
  toEditAssetPlanStep: (index: number) => void
  resetEditAssetPlan: () => void
  updateEditAssetPlanBatteryChargeData: (index: number, value: number) => void
  setEditAssetPlanBatteryChargeData: (values: number[]) => void
  updateEditAssetPlanDemandData: (index: number, value: number) => void
  setEditAssetPlanDemandData: (values: number[]) => void
}

const getInitialState = () => ({
  editAssetPlanDate: addDays(getCurrentJpDayStart(), 1),
  editAssetPlanStepIndex: 0,
  editAssetPlanIsConsentAgreed: false,
  editAssetPlanBatteryChargeData: new Array(48).fill(0),
  editAssetPlanDemandData: new Array(48).fill(0),
})

export const createEditAssetPlanSlice: StoreSlice<EditAssetPlanSlice> = (set, _get) => ({
  ...getInitialState(),
  setEditAssetPlanDate: (date: Date) => set({ editAssetPlanDate: date }),
  toggleEditAssetPlanConsentAgreement: () =>
    set((state) => ({
      editAssetPlanIsConsentAgreed: !state.editAssetPlanIsConsentAgreed,
    })),
  toEditAssetPlanStep: (index: number) =>
    set({ editAssetPlanStepIndex: Math.max(Math.min(index, 1), 0) }),
  toEditAssetPlanNextStep: () =>
    set((state) => ({
      editAssetPlanStepIndex: Math.min(state.editAssetPlanStepIndex + 1, 1),
    })),
  toEditAssetPlanPrevStep: () =>
    set((state) => ({
      editAssetPlanStepIndex: Math.max(state.editAssetPlanStepIndex - 1, 0),
    })),
  resetEditAssetPlan: () => set(getInitialState()),
  updateEditAssetPlanBatteryChargeData: (index: number, value: number) =>
    set((state) => {
      const newBatteryChargeData = [...state.editAssetPlanBatteryChargeData]
      newBatteryChargeData[index] = value
      return {
        editAssetPlanBatteryChargeData: newBatteryChargeData,
      }
    }),
  setEditAssetPlanBatteryChargeData: (values: number[]) =>
    set({ editAssetPlanBatteryChargeData: values }),
  updateEditAssetPlanDemandData: (index: number, value: number) =>
    set((state) => {
      const newDemandData = [...state.editAssetPlanDemandData]
      newDemandData[index] = value
      return {
        editAssetPlanDemandData: newDemandData,
      }
    }),
  setEditAssetPlanDemandData: (values: number[]) => set({ editAssetPlanDemandData: values }),
})
