import { SortOrder } from './types'
import { unique } from './unique'

type Options = { order?: SortOrder; locale?: string }

const zones = {
  en: [
    'system',
    'asset_grid_area',
    'chubu',
    'chugoku',
    'hokkaido',
    'hokuriku',
    'kansai',
    'kyushu',
    'okinawa',
    'shikoku',
    'tohoku',
    'tokyo',
    'sys',
    'hkd',
    'toh',
    'tok',
    'hok',
    'chb',
    'kan',
    'chg',
    'shi',
    'kyu',
    'oki',
  ],
  ja: [
    'system',
    'asset_grid_area',
    'hokkaido',
    'tohoku',
    'tokyo',
    'chubu',
    'hokuriku',
    'kansai',
    'chugoku',
    'shikoku',
    'kyushu',
    'okinawa',
    'システム',
    'エリア',
    '北海道',
    '東北',
    '東京',
    '中部',
    '北陸',
    '関西',
    '中国',
    '四国',
    '九州',
    '沖縄',
  ],
}

export function sortByUtilityZone<T extends string | number | Record<string, any>>(
  arr: T[],
  options?: Options | null,
  accessor: (el: T) => string = (el) => el as unknown as string
): T[] {
  const uniqueZones = unique([...arr])
  const order = options?.order ?? 'asc'
  const locale = options?.locale === 'ja' ? 'ja' : 'en'

  return uniqueZones.sort((a, b) => {
    const indexA = zones[locale].indexOf(accessor(a).toLocaleLowerCase())
    const indexB = zones[locale].indexOf(accessor(b).toLocaleLowerCase())
    return order === 'asc' ? indexA - indexB : indexB - indexA
  })
}
